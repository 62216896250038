/*
 * The .box-widget in combination with .widget-user is the primary-colored box used for example on the profile page.
 */

.box-widget {
    border  : none;
    position: relative;
}

.widget-user {
    color: #fff;

    & .widget-user-header {
        padding                : 20px;
        min-height             : 120px;
        border-top-right-radius: 3px;
        border-top-left-radius : 3px;
    }

    & .widget-user-username {
        margin-top   : 0;
        margin-bottom: 5px;
        font-size    : 25px;
        font-weight  : 300;
        text-shadow  : 0 1px 1px rgba(0, 0, 0, 0.2);
    }

    & .widget-user-desc {
        margin-top: 0;
    }

    & .widget-user-image {
        position   : absolute;
        top        : 65px;
        left       : 50%;
        margin-left: -45px;
    }

    & .widget-user-image>img {
        width : 90px;
        height: auto;
        border: 3px solid #fff;
    }

    & .box-footer {
        padding-top: 30px;
    }
}

.widget-votings {
    & .widget-votings-header {
        padding                : 20px;
        min-height             : 120px;
        border-top-right-radius: 3px;
        border-top-left-radius : 3px;
    }

    & .widget-votings-username {
        margin-top   : 0;
        margin-bottom: 5px;
        font-size    : 25px;
        font-weight  : 300;
        text-shadow  : 0 1px 1px rgba(0, 0, 0, 0.2);
    }

    & .widget-votings-desc {
        margin-top: 0;
    }

    & .widget-votings-image {
        position   : absolute;
        top        : 65px;
        left       : 50%;
        margin-left: -45px;
    }

    & .widget-votings-image>img {
        width : 90px;
        height: auto;
        border: 3px solid #fff;
    }

    & .box-footer {
        padding-top: 30px;
    }
}