#supportPin {
    font-size: 3em; 
    color: var(--primary); 
    letter-spacing: 10px; 
    font-family: monospace;
}

#priceTable {
    tr.border-top-black {
        border-top: 2px solid black;
    }
    tr.border-top-gray {
        border-top: 2px solid gray;
    }
    tr.dotted {
        border-style: dotted;
    }
    tr.double {
        border-style: double;
    }
}

.text-blue{color:#0073b7}
