#publicCheck, #commentCheck {
    font-weight: normal !important;
}

#sendAgain, #sendFirst {
    font-weight: normal !important;
}

#modalHead {
    height:50%;
}

#modalBody {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
}

.img-task-comment-rounded {
    border-radius: 30px;
}

.listBoxShadowTask {
    margin-bottom: 10px;
    padding: 14px !important;
    border-radius: 7px !important;
    border-bottom: 1px solid #f4f4f4;
    box-shadow: 0rem 0rem 3px 3px rgba(209, 209, 209, 0.2) !important;
}

.products-list.product-list-in-box .listBoxShadowTask:last-of-type {
    border-bottom: none;
}

.listBoxShadowTask:hover {
    box-shadow: 0rem 0rem 3px 3px rgba(34, 153, 94, 0.2) !important;
}

.skin-dark {
    .listBoxShadowTask {
        margin-bottom: 10px;
        padding: 14px !important;
        border-radius: 7px !important;
        border-bottom: 1px solid #3a3a3a !important;
        box-shadow: 0rem 0rem 3px 3px rgba(63, 63, 63, 0.2) !important;
    }

    .products-list.product-list-in-box .listBoxShadowTask:last-of-type {
        border-bottom: none;
    }

    .listBoxShadowTask:hover {
        box-shadow: 0rem 0rem 3px 3px rgba(34, 153, 94, 0.2) !important;
    }
}

.searchTaskBoard {
    color: $navbar-dark-color;
    #searchTaskBoard {
        background-color: shift-color($color: $primary, $weight: 10%);
        color: $navbar-dark-active-color;
        border-color: transparent;
        border-top: unset;
        min-width: 19rem;
        padding-right: 2.4rem;
    }
    .form-floating label {
        & > i, & > svg {
            color: $navbar-dark-color;
        }
        &:active, &:focus, &:focus-within {
            & > i, & > svg {
                color: $navbar-dark-active-color;
            }
        }
    }

    #emptySearchTaskBoard {
        background-color: shift-color($color: $primary, $weight: 10%);
        border-radius: 0;
        margin-top: -2.4rem;
        border: none;
        float: right;
    }
}

.preDescrText{
    font-family: var(--bs-body-font-family);
    font-weight: 400;
}
