#appIdentityProvider {
    .deleteApplication {
        color: var(--bs-danger);
    }

    .deleteApplication:active {
        color: #fff;
        background-color: var(--bs-danger);
    }
}
