#builder.query-builder .rule-value-container {
    input[type=number], input[type=text], select {
        padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
    }
}

// cause that queryBuilder guys dosent realize this class is not existing anymore in bs5
#builder.query-builder .pull-right {
    float: right !important;
}

// fix for querybuilder in darkmode
.rule-operator-container {
    color: #000;
}