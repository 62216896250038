#appBookkeepingInvoicesAdd, #appBookkeepingInvoice {
    #refNumber:invalid {
        background: var(--warning);
    }

    #refNumberWrapper {
        font-weight: normal !important;
    }

    #users {
        max-height: 15rem !important;
        overflow-y: scroll;
        border: 1px solid var(--primary);
        padding: 10px;
        border-radius: 0.375rem !important;
    }

    #invoiceDetails {
        height: 1000px;
        min-height: 1000px;
    }

    #pdfIcon {
        font-size: 10em;
    }

    #invoicePreview {
        height: 1000px;
    }

    #viewerWrapper {
        clear: both;
    }

    .deleteOption {
        color: var(--bs-danger);
    }

    .deleteOption:active {
        color: #fff;
        background-color: var(--bs-danger);
    }
}
