$ev-theme: 'default';

$primary: hsl(150, 63%, 37%);
$primary-darker: hsl(150, 63%, 30%);
$primary-light: rgb(226, 248, 237);
$info: hsl(0, 0%, 70%);
$info-light: hsl(0, 0%, 95%);
$warning: hsl(41, 100%, 51%);
$warning-light: hsla(41, 94%, 68%, 0.334);
$danger: hsl(354, 70%, 54%);
$danger-light: rgba(245, 186, 186, 0.62);
$success: hsl(131, 45%, 44%);
$success-light: rgba(191, 233, 212, 0.51);
$text-foreground-subtle: hsl(0, 0%, 29%);
$text-foreground-muted: hsl(0, 0%, 60%);
$table-header-default-color: hsl(0, 0%, 95%);

$colors:(
    "primary": $primary,
    "primary-darker": $primary-darker,
    "primary-light": $primary-light,
    "info": $info,
    "info-light": $info-light,
    "warning": $warning,
    "warning-light": $warning-light,
    "danger": $danger,
    "danger-light": $danger-light,
    "success": $success,
    "success-light": $success-light,
    "text-foreground-subtle": $text-foreground-subtle,
    "text-foreground-muted": $text-foreground-muted,
    "table-header-default-color": $table-header-default-color,
);

:root {
    @each $key, $color in $colors {
        --#{$key}: #{$color};
    }

    --primary-active: hsl(150deg, 63%, 47%);
}