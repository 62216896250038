#appInventory {
    .imageColumn {
        width: 60px;
    }

    #returnDateModal {
        z-index: 5000;
    }

    .elementImg {
        width: 200px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        display: block
    }

    .sortable {
        list-style-type: none;
        margin: 0;
        padding: 0;
        min-width: 30%;
        min-height: 2.13rem;

        li {
            margin: 0.3rem 0.06rem 0.3rem 0.06rem;
            padding: 0.4rem;
            display:inline-block;
            vertical-align: top;
            background-color: var(--gray-900);
            color: var(--gray-200) !important;
            border: 0.06rem solid hsl(0, 0%, 70%);
            border-radius: 0.3rem;
            text-align: left
        }
    }

    .stickyBellowHeader {
        top: 3.75rem !important;
    }

    #tableFields li {
            margin: 0.06rem;
            padding: 0.4rem;
            display:inline-block;
            vertical-align: top;
            background-color: hsla(150, 63%, 37%, 0.3);
            color: var(--gray-200) !important;
            border: 0.06rem solid hsla(150, 63%, 37%, 0.3);
            border-radius: 0.3rem;
            text-align: left;
        }

    #articleTableFields li {
        margin: 0.06rem;
        padding: 0.4rem;
        display:inline-block;
        vertical-align: top;
        background-color: hsla(150, 63%, 37%, 0.3);
        color: var(--gray-200) !important;
        border: 0.06rem solid hsla(150, 63%, 37%, 0.3);
        border-radius: 0.3rem;
        text-align: left;
    }

    #tableFieldsContainer {
        max-width: none;
    }

    .accordion-button {
        margin-top: 0.44rem;
        margin-bottom: 0.44rem;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
    
        &.collapsed {
            border-radius: 0.44rem !important;
            background-color: transparent;
            font-weight: normal;
            overflow: hidden; // Ensure the pseudo-element doesn't overflow
    
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--bs-gray-500);
                opacity: 0.3;
            }
        }
    
        &:not(.collapsed) {
            border-radius: 0.44rem !important;
            box-shadow: 0.3rem 0 0 0 var(--primary) inset !important;
            color: var(--bs-accordion-active-color);
            background-color: transparent;
            font-weight: bold;
            overflow: hidden; // Ensure the pseudo-element doesn't overflow
    
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--primary);
                opacity: 0.2;
            }
        }
    }
    
    .accordion-item {
        border: unset;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }
}