#appRegisterSuccess #helpButtonHolder {
	display: none;
}

#appRegisterSuccess .content-wrapper,
#appRegisterSuccess .right-side,
#appRegisterSuccess .main-footer {
	margin-left: 0rem; bottom: 0rem;
}

#appRegisterSuccess .invalid input {
	background-color: var(--danger);
}

#appRegisterSuccess .buttonHolder {
	width: 25.5rem;
}