.copyright-label {
    border-radius: 5px 0px 5px 0px;
}

.expand {
    transition: height 0.3s;
    height: 0%;
}

.expandOnHover:hover {
    & .expand {
        height: 100%;
    }
}