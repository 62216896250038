.benefitBox {
    min-height: 430px;
}

.benefitLogo {
    margin: 0px 35px 35px 35px;
}

.percantageIcon {
    font-size: 180px;
}

.benefitTextLarge {
    font-size: 19px;
}

.benefitTextSmall {
    color: grey;
    font-size: 12px;
}