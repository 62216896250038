figure {
    &.table,
    &.image {
        margin: 0.9em auto;
    }

    &.table table {
        width: 100%;
        border-style: solid;
        border-color: black;
        border-width: 1px;
    }

    &.table table td {
        padding: 0.4em;
        border-style: solid;
        border-color: black;
        border-width: 1px;
    }

    &.image img {
        width: 100%;
    }

    &.image-style-side {
        float: right;
        margin-left: 1.5em;
    }
}

figure span.text-tiny {
    font-size: .7em;
}

figure span.text-small {
    font-size: .85em;
}

figure span.text-big {
    font-size: 1.4em;
}

figure span.text-huge {
    font-size: 1.8em;
}

figure mark.marker-yellow {
    background-color: #fdfd77;
}

figure mark.marker-green {
    background-color: #62f962;
}

figure mark.marker-pink {
    background-color: #fc7899;
}

figure mark.marker-blue {
    background-color: #72ccfd;
}

figure mark.pen-red {
    background-color: transparent;
    color: #e71313;
}

figure mark.pen-green {
    background-color: transparent;
    color: #128a00;
}

figure blockquote {
    font-style: italic;
}