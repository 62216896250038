$notification-color: #f5a742;

.unreadMessages-dot {
    height: 10px;
    width: 10px;
    background-color: $notification-color;
    border-radius: 50%;
    display: inline-block;
    animation: unreadMessages-dot-pulse 2s infinite;
}

@keyframes unreadMessages-dot-pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba($notification-color, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba($notification-color, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba($notification-color, 0);
	}
}
