.licenseChooser-swiper {

    & .swiper-slide-shadow {
        background-image: unset !important;
        background: unset !important;
    }

    & .swiper-slide-active {
        z-index: 0 !important;
    }

    .swiper-pagination-bullet {
        --swiper-pagination-bullet-inactive-color: var(--bs-gray-700);
        --swiper-pagination-color: var(--primary);
    }

    .swiper-button-next, .swiper-button-prev  {
        color: #fff !important;
        background-color: var(--primary) !important;
        border-radius: 0.188rem !important;
    }
}

.swiper-scrollbar-drag {
    background: var(--primary) !important;
}

.licenseChooserSwiperPagingBtn {
    font-size: 2.2rem;
}

.licenseChooser-swiperCard {
    border-top: 0.188 solid var(--primary) !important;
    width: 18.75rem;
}

.licenseChooser-demoLink {
    white-space: normal;
    border-radius: 0 !important;
}

.box-licenseRequest {
    min-height: 18rem;
}

.box-body-licenseRequest {
    min-height: 24rem;
}

#licenseChooser {

    & fieldset {
        background: #fff;
        border: 0rem none;
        border-radius: 0.5rem;
        box-sizing: border-box;
        width: 100%;
        margin: 0rem;
        padding-bottom: 1.25rem;
        position: relative
    }

    & fieldset:not(:first-of-type) {
        display: none
    }

    & .previous-step, .next-step, .last-step {
        margin: 0.625rem 0.313rem 0.625rem 0rem;
    }

    & .previous-step-effect:hover, .previous-step-effect:focus {
        background-color: #000000
    }

    & .next-step-effect:hover, .next-step-effect:focus {
        background-color: var(--primary) !important
    }

    & #licenseTablePrice {
        max-width: 32rem;
    }
}

#licenseChooser-progressbar {

    .licenseChooser-progressbar-step {
        $bg-color: $gray-300;
        $color: color-contrast($bg-color);

        list-style-type: none;
        font-size: 1rem;
        width: 25%;
        float: left;
        position: relative;
        font-weight: 400;

        &::after {
            content: '';
            width: 100%;
            height: 0.125rem;
            background: $bg-color;
            position: absolute;
            left: 0;
            top: 1.563rem;
            z-index: -1
        }

        &::before {
            width: 3.5rem;
            height: 3.5rem;
            line-height: 3.1rem;
            display: block;
            font-size: 1.25rem;
            color: $color;
            background: $bg-color;
            border-radius: 50%;
            margin: 0rem auto 0.625rem auto;
            padding: 0.125rem
        }

        &.active {
            color: var(--primary) !important;

            .progressBarText {
                font-weight: bold;
            }
        }

        & > .progressBarText {
            font-weight: normal;
        }
    }


    li.active::after, li.active::before {
        background: var(--primary) !important;
        color: color-contrast($primary);
    }
}

#licenseChooser .progress {
    background-color: $gray-300;
    height: 1.25rem;
    margin-top: -5.5rem;
}

#licenseChooser .progress-bar {
    background-color: var(--primary) !important;
}

.bg-transparent-important {
    background-color: transparent !important;
}

@media (max-width: 1000px) {
    #licenseChooser .progress {
        margin-top: 0rem;
    }

    #licenseChooser {
        margin-top: 0rem;
    }

    .progressBarText, .progressBarSeperator {
        display: none;
    }

    .licenseChooser-swiper {

        & .swiper-button-prev, .swiper-button-next  {
            display: none;
        }

        & .swiper-wrapper, .swiper-slide {
            height: 31.875rem !important;
        }
    }
}