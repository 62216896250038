#queues {
  padding: 5px 0px;
}

.queue {
  background-color: white;
  padding: 1rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  margin-top: 1em;
}

.queueProgress {
  background-color: #222d32;
  position: relative;
  border-radius: 8px;
  border: 1px solid #0f4c00;
}

.queueProgress__title {
  color: #f4f4f4;
  margin: 0;
  width: 100%;
  text-align: center;
  padding: 3px;
  z-index: 2;
  position: relative;
}

.queueProgress__bar {
  background-color: #00a65a;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  transition: width 1s, background-color 1s;
  border-radius: 7px;
}

.queueDates {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}

.queueActions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
}

.queue-banking .queueActions {
  justify-content: flex-end;
}

.queueAction.queueAction--hidden {
  visibility: hidden;
}

.template, .template-banking {
  display: none;
}

@keyframes loadingShimmer {
  0% {
    background-position: calc(0% - 10px) 0
  }
  100% {
    background-position: calc(100% + 10px) 0
  }
}

.badge--tasks {
  color: #ffffff;
  font-size: 10px;
  background-color: transparent;
}

.badge--tasks--wrapper {
  margin:0;
  padding: 0;
  font-size: 75%;
  background-color: #f39c12;
  position: relative;
  top: -1px;
}

.loading-shimmer {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadingShimmer;
  background: linear-gradient(to right, #f39c12 , #fae18d 50%);
  background-repeat: no-repeat;
  background-size: 10px 100%;
}

.stalled .loading-shimmer {
  animation: none;
  background-position: calc(100% + 3px) 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.queueProgress__bar .loading-shimmer {
  display: none;
}

.loading .queueProgress__bar .loading-shimmer {
  display: block;
  z-index: 2;
  background-image: linear-gradient(to right, #00a65a , #67d880, #00a65a);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 7px;
}

.accountList .accountProgress {
  margin-bottom: 2em;
}

.accountList .accountProgress .challengeButton {
  display: none;
  margin-top: 1em;
}

#currentlyRunningTaskPreview {
  width: 0;
  transition: width 1s;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block !important;
  vertical-align: middle;
}

@media (min-width: 768px) {
  #currentlyRunningTaskPreview.show {
    width: 100px;
    margin-right: 1em;
  }
}

@media (min-width: 992px) {
  #currentlyRunningTaskPreview.show {
    width: 200px;
  }
}

@media (min-width: 1200px) {
  #currentlyRunningTaskPreview.show {
    width: 300px;
  }
}

#currentlyRunningTaskPreview .queueProgress {
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  background-color: #2d6d41;
}

#currentlyRunningTaskPreview .queueProgress__bar {
  width: 0%;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background-color: #f39c11;
}

#currentlyRunningTaskPreview.loading .queueProgress__bar {
  max-width: 98%;
}

#currentlyRunningTaskPreview.finished .queueProgress__bar {
  background-color: #3bca00;
  border-radius: 7px;
}

#currentlyRunningTaskPreview.stalled .queueProgress__bar {
  background-color: #ff7e31;
}

#currentlyRunningTaskPreview.failure .queueProgress__bar {
  background-color: #dd4a39;
  border-radius: 7px;
}

#currentlyRunningTaskPreview .loading-shimmer {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-image: linear-gradient(to right, #f39c12 , #fae18d 50%);
}

#flashDiv {
  min-width: 3.313rem;
}