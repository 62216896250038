#appEvents {
    #part0SlideToggle,#part1SlideToggle,#part2SlideToggle,#part4SlideToggle {
        font-size: 0.6em;
    }

    .groupSizing {
        font-size: 0.85em;
    }

    .biggerStrong {
        font-size: 1.3em;
    }

    #invitationGroupsSlideToggle, #addressInvitations, #joinEvent, #priceGroupsDisplay {
        white-space: normal !important;
    }

    .external-event {
        padding      : 5px 10px;
        font-weight  : bold;
        margin-bottom: 4px;
        box-shadow   : 0 1px 1px rgba(0, 0, 0, 0.1);
        text-shadow  : 0 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        cursor       : move;

        &:hover {
            box-shadow: inset 0 0 90px rgba(0, 0, 0, 0.2);
        }
    }

    // This affects the field in which members can be searched for in order to invite them to an event
    // Because "typeahead" is used, a span is created around the input field
    // which makes the selector in the bootstrap code, which is supposed to make these corners not rounded, useless
    // because it selects direct children only
    #pName {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

// prevents bootstrap from computing height and ignoring rows attribute
#event textarea {
    height: unset;
}
