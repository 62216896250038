#appAddressbookMailings {
	#users.groupSelect {
		max-height: 15rem !important;
		overflow-y: scroll !important;
		border: 0;
	}

	// Makes the background transparent because of overlaying the editor-modal
	#addressGroupModal, #userGroupModal {
		background: rgba(0, 0, 0, 0) !important;
	}

	#groupAddressDropdownButton {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	#addressSuggestion {
		border-top-right-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		border-color: hsl(150, 63%, 37%);
		border-right-width: 0;
	}

	.addressBox {
		border-bottom-left-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
		margin-top: -0.075rem !important;
	}

	.orderSequence {
		min-width: 5rem !important;
	}

	.dropArea {
		padding: 0 !important;
		border: none !important;
		margin-top: 0 !important;
		flex-direction: row !important;
		height: 100% !important;
	}

	.dropArea .dropAreaTitle {
		margin: initial !important;
	}

	.dropArea .border-dashed:hover .dropAreaTitle {
		color: #fff !important;
	}


	#newDocumentTemplate {
		border-radius: 1rem;
	}

	.card {
		border-radius: 1rem;
		border: 1px solid #00000026;
		height: 100% !important;


		a {
			color: inherit;
			text-decoration: none;
		}

		.card-body {
			padding-bottom: 0;
		}
		.card-footer {
			border-top: none;
			background-color: transparent;
		}
		.card-header {
			position: relative;
			border-bottom: none;
			background-color: $gray-100;
			padding: 0;
			border-top-left-radius: 1rem;
			border-top-right-radius: 1rem;

			&:hover {
				.overlay {
					display: flex;
					z-index: 72; // Otherwise the blur thumbnail would be above it
				}
				.dropdown {
					z-index: 73;
				}
				.dropdown-button {
					color: white;
				}
				.thumbnail {
					filter: blur(5px);
					-webkit-filter: blur(5px);
				}
				.thumbnailContainer {
					overflow: hidden;
				}
			}

			.thumbnailContainer {
				width: 100%;
				height: 100%;
				padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
				padding-bottom: 0;
			}

			.thumbnail {
				height: 5.5rem;
				background-repeat: no-repeat;
				background-position: center;
			}

			.placeholderImage {
				height: 5.5rem;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url("/static/img/letter.png");
			}

			.dropdown {
				position: absolute;
				right: 0;
				top: 0;

				.dropdown-button {
					font-size: 1.3rem;
					padding: 0.5rem 1rem;
				}
			}
			.overlay {
				display: none;
				background-color: #00000094;
				color: white;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				border-top-left-radius: 1rem;
				border-top-right-radius: 1rem;
			}
		}
	}

	.category.active {
		@if $ev-theme == 'dark' {
			box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
		} @else {
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		}
	}
	#includedText {
		@if $ev-theme == 'dark' {
			color: var(--bs-gray-900);
		} @else {
			color: black;
		}
	}
	#excludedText {
		@if $ev-theme == 'dark' {
			color: var(--bs-gray-900);
		} @else {
			color: black;
		}
		
	}
	
	.previewPlaceholder {
		width: 9rem;
	}
}
