.pin-input-group {
    min-width: 226px; 
    max-width: 30rem;
}

.pin-input-span {
    padding-left: 15px;
}

.emailVerifySuccess {
    color: green; 
    font-size: 20px;
}

.verifyEmailField {
    border: none;
    box-shadow: none;
    color: gray;
    font-weight: 400;
}