
#appProfile {
	--image-height: 145px;
	--image-width: 145px;

	@media (max-width: 1600px) {
		.height-sm {
			min-height: 11rem !important;
		}
	}

	#profileInfoRow.showProfilePicture {
		margin-top: calc(var(--image-height) / 2);

	}

	#profileInfoRow:not(.showProfilePicture) .profilePictureHolder {
		display: none;
	}

	.profilePictureHolder {
		position: absolute;
		left: calc(50% - calc(var(--image-width) / 2));
		top: calc(var(--image-height)/2* -1);
		z-index: 99;
	}

	.profilePictureHolder > .img-circle {
		height: var(--image-height);
		width: var(--image-width);
	}


	// Profile Picture Animation

	.profile-picture {
		padding: 0.2rem;
		border: none;
	}

	#profilePicture {
		transition: all 0.3s ease;
	}

	#header-wrapper {
		transition: all 0.3s ease;
	}

	.large-picture {
		width: 146px;
		height: 146px;
	}

	.small-picture {
		width: 41px;
		height: 41px;
	}

	.small-header {
		height: 59px;
		overflow: hidden;
	}

	.large-header {
		height: 113px;
	}

	#tabTitle {
		transition: all 0.3s ease;
	}

	.small-Tab-Title {
		font-size: 1.2rem;
		margin-top: 13px;
	}

	.profilePicRow-small {
		top: 4.1rem !important;
		margin-top: 2rem;
	}

	#profilePictureRow {
		transition: all 0.3s ease;
	}
	.dataTables_scrollHead, div.stickyTableHead, thead {
		top: 8rem;
	}
	// Top bar and Header text

	.tabTitleContainer {
		margin-left: 19%;
	}

	.topBarBG {
		background-color: #f0f9f4;
	}
	.topBarBGDark {
		background-color: #2e3130;
	}

	.payment-divider {
		font-size: 1.5rem;
		position: relative;
		overflow: hidden;
		text-align: left;

		&:after {
			--height: 1px;
			display: inline-block;
			content: "";
			height: var(--height);
			background: var(--bs-gray-500);
			position: absolute;
			width: 100%;
			top: calc(50% - var(--height));
			margin-left: 1rem;
		}
	}

	.relatedMembersLinkBtn {
		min-width: 75% !important;
	}

	.relationArrowDown { display: none; }
	.relationArrowRight { display: initial; }
	.showCurrentMember:disabled {
		background: var(--success-light);
	}

	@media (max-width: 1200px) {
		.relationArrowDown { display: initial; }
		.relationArrowRight { display: none; }
	}

	// Start memberListProfile Widget
	.btn-profile-member-list, .btn-profile-member-list-sm {
		height: 41px;
		width: 41px;
		border: unset;
		margin-top: 10px;
		margin-left: 10px;
		z-index: 1024 !important;
		top: 57px !important;
	}
	#memberListProfileWrapper {
		position: -webkit-sticky;
		position: sticky;
		top: 63px !important;
		display: flex;
		flex-direction: column;
		background-color: var(--bs-body-bg);
	}
	#memberListProfile {
		overflow: auto !important;
		flex-grow: 1;
	}
	#loadMoreMembers {
		width: 100%;
	}
	#loadNextMembersLink {
		border-bottom-right-radius: 0.5rem !important;
	}
	#loadPreviousMembersLink {
		border-bottom-left-radius: 0.5rem !important;
	}
	#loadNextMembersLink.disabled, #loadPreviousMembersLink.disabled {
		pointer-events: none !important;
	}
	#searchMembers {
		border-top: unset !important;
		border-right: unset !important;
		border-left: unset !important;
		border-bottom: 1px solid #f4f4f4;
	}
	#memberListProfileAlert {
		border: unset !important;
	}
	.memberListProfileSelected {
		background-color: var(--success-light);
		box-shadow: 5px 0px 0px 0px var(--primary) inset !important;
	}
	#searchMembers:hover, #searchMembers:focus {
		box-shadow: 0 4px 3px -3px rgba(209, 209, 209, 0.2) !important;
	}
	.products-list .item .memberListProfile-img img {
		width: 40px !important;
		height: 40px !important;
	}
	.products-list .item .memberListProfile-info {
		margin-left: 50px !important;
	}
	#memberListProfile .listBoxShadow {
		padding: 10px !important;
	}
	#memberSearchProfileWrapper .tt-menu {
		min-width: unset !important;
		z-index: 2048 !important;
	}
	#memberSearchProfileWidget, #memberListSearchToast {
		top: 63px !important;
		z-index: 1024 !important;
	}
	@media (max-width: 1700px) {
		#memberListProfileCol { display: none; }
		.btn-profile-member-list { display: none; }
		#memberSearchProfileWidget { display: block; }
		.btn-profile-member-list-sm { display: block; }
		#profileContentCol {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	@media (min-width: 1700px) {
		#memberSearchProfileWidget { display: none; }
		.btn-profile-member-list-sm { display: none; }
	}
	// End memberListProfile Widget

	.content {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

	.invalidQrCode {
		filter: blur(1.5rem);
		-webkit-transition: .50s ease-in-out;
		transition: .50s ease-in-out;	   
	}

	.firstInfoGroups {
		max-width: 24rem;
	}
}

.spacer {
	display: none;
}

.imgRemoveButtonSmall {
	display: none;
}

.imgRemoveButton {
	display: block
}

.profilePicture {
	display: inline-block;
	width: 120px !important;
	height: 120px !important;
	margin-top: -170px;
}

#deleteUserButton:active {
	color: #fff !important;
	text-decoration: none;
	background-color: var(--danger);
}

@media(max-width: 500px) {
	.spacer {
		height: 30px;
		background: transparent;
		display: inline-block;
	}
	.profilePicture {
		margin-top: -230px;
	}
}
@media(max-width: 450px) {
	.imgRemoveButtonSmall { display: block; }
	.imgRemoveButton { display: none }
}


.table { margin-bottom: 0px; }
.dataTables_paginate { margin: 18px !important; }
.dataTables_info { padding-top: 18px !important; }

#mapHolder {
	height: 15rem;
	width: 15rem;
}

#activePayments {
	box-shadow: 0 0.125rem 0.25rem rgba(61, 162, 80, 0.5) !important;
}

#inactivePayments {
	box-shadow: 0 0.125rem 0.25rem rgba(220, 53, 69, 0.5) !important;
}

.notActive {
	color: var(--bs-gray-500);
}

.tabTitleDiv {
	left: 25%;
	#tabTitle {
		margin-bottom: 0rem;
	}
}

.editPictureIcon {
	left: 57%;
	background-color: var(--bs-gray-300)
}

#hiddenMapOverflow {
	display: none;
	background-color: var(--bs-gray-300);
	z-index: 98;
	width: 15rem;
	height: 15rem;
}

#mapHolder {
	&:hover {
		#hiddenMapOverflow {
			display: block !important;
		}
	}
}

#mapdiv {
	height: 38rem;
}

#topBar {
	max-height: 9rem;
	min-height: 7rem;
}

#stickyBar {
	top: 3.25rem;
	z-index: 99; // The bootstrap value for this is 1020 from .sticky-top (to hight so the sidebar on the left would be behind it on mobile)
}

#stickyBarText {
	width: 99%;
	.row {
		width: 99%;
	}
}

#profilePictureRow {
	top: 8.5rem !important;
	margin-top: -5rem;
	pointer-events: none;
	#profilePicture, .editPictureIcon {
		pointer-events: all;
	}
}

.box {
	border: none !important;
}

#tabMenu .nav-link {
	border-radius: 7px !important;
}

#tabMenu .nav-link.active {
    position: relative;
    box-shadow: 5px 0px 0px 0px var(--primary) inset !important;
    border: unset;
    overflow: hidden; // Ensure the pseudo-element doesn't overflow
}

#tabMenu .nav-link.active::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    opacity: 0.2;
}

.accordion-button:after {
	order: -1;
    margin-left: 0;
    margin-right:0.5em;
}

#groupTable, #individualPaymentRow {
	.form-control {
		height: 1rem;
		min-height: 1rem;
	}
	.form-floating > label {
		padding: 0.25rem;
	}
}

// Accordion

.accordion-button {
	margin-top: 7px;
	margin-bottom: 7px;
}

.accordion-button:not(.collapsed) {
	border-radius: 7px !important;
    box-shadow: 5px 0px 0px 0px var(--primary) inset !important;
	color: var(--bs-accordion-active-color);
	background-color: transparent;
	font-weight: bold;
    overflow: hidden; // Ensure the pseudo-element doesn't overflow
}

.accordion-button.collapsed {
	border-radius: 7px !important;
	background-color: transparent;
	font-weight: normal;
    overflow: hidden; // Ensure the pseudo-element doesn't overflow
}

.accordion-button:not(.collapsed)::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--primary);
	opacity: 0.2;
}

.accordion-button.collapsed::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--bs-gray-500);
	opacity: 0.3;
}

.accordion-item {
	border: unset;
	margin-top: 5px;
	margin-bottom: 5px;
}

// Drop Area

.dropArea {
	border: 1.5px dashed var(--primary);
	border-radius: 7px;
}

.deleteFileCheckboxBorder {
	border: 1px solid var(--bs-gray-300);
	border-radius: 7px;
}

.ml-2 { // For some reason the Bootstrap class did not work here
	margin-left: .5rem;
}

.firstInfoGroups {
	padding-right: .75rem;
	padding-left: .75rem;
}

.btn-add-group-small {
	padding    : 0.75px 20px;
    text-align : center;
    color      : var(--primary) !important;
    text-decoration: none !important;
    display: inline-block;
    font-size: 1rem !important;
	border: 1px dashed var(--primary) !important;
	background: unset;
	white-space: nowrap;
}

.btn-add-group-small:hover, .btn-add-group-small.show {
	color      : #fff !important;
	background: var(--primary) !important;
}

#groupsAddDropDown-generalInfos {
	vertical-align: initial !important;
}

@media only screen and (max-width: 1166px){
	.hide-on-mobile {
		display: none;
	}
}


// Memberships List

.small-input {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	height: 30px !important;
	min-height: 30px !important;
}

.custom-invoicing-pill {
	border: 1px solid var(--bs-gray-400);
	background-color: var(--bs-gray-200);
}

.bg-success-light {
	background-color: var(--success-light);
}

.bg-danger-light {
	background-color: var(-danger-light);
}

.bg-warning-light {
	background-color: var(-warning-light);
}

.customFieldDiv {
	margin-bottom: 2.5rem;
	margin-top: 1rem;

	label {
		word-break: break-all;
	}
}

.xsLoadingIndicator {
	width: 1rem;
}