.ui-sortable-handle {
    cursor: move;
}
.sortable-form {
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-height: 100px;
}

.sortable-form li {
    margin-bottom: 10px;
    padding: 0.7em;
    background-color: $body-bg;
    border: $border-width solid $gray-500;
    border-radius: $border-radius;
    text-align: left;
    width: 100%;
}

.applicationform-sortable-helper {
    padding: 0.7em;
    background-color: $body-bg;
    border: $border-width solid var(--primary);
    border-radius: $border-radius;
    text-align: left;
    width: 100%;
    height: auto !important;
}

.sortable-form li strong,
.applicationform-sortable-helper strong {
    color: var(--primary);
    line-height: 2em;
}