@use 'sass:math';

#appSettingsDocumentTemplatesChange {
    .full-height {
        @include media-breakpoint-up(xxl) {
            min-height: calc(100vh - var(--main-header-height) - var(--main-footer-height) - 2rem);
        }
    }
    .dropArea {
        .imageContainer {
            position: relative;
            img {
                max-height: 12rem;
                border-radius: .5rem;
                max-width: 100%;
            }

            .removeImage {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    @media only screen and (max-width: 74.938rem) {
        .extraHeightOnSmallScreens {
            height: 81.25rem !important;
        }
    }

    .margin-widget {
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 0;
        padding-top: percentage(math.div(402, 296));
        position: relative;

        input {
            width: auto;
        }

        > div {
            position: absolute;
            top: 10%;
            right: 13%;
            bottom: 14%;
            left: 13%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            &.leftRightMargins {
                top: calc(23% - 17.75px);
                bottom: calc(26% - 17.75px);
            }

            .form-control {
                max-width: 5rem !important;
            }
        }
        &.addressBoxMargins {
            background-size: 70%;
            background-position: center;
            padding-top: percentage(math.div(266 * 0.6, 296));

            > div {
                position: absolute;
                top: 14%;
                right: 21%;
                bottom: 21%;
                left: 21%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                & .leftMarginAndHeight {
                    position: absolute;
                    left: 3%;
                    right: 23%;
                    top: 82%;
                    &:has(input[name*="returnAddress"]) {
                        top: 23%;
                    }
                }

                .returnAddressBoxMarginTopContainer {
                    top: 0;
                }

                .returnAddressBoxWidthContainer {
                    top: 33%;
                }

                .returnAddressToAddressDistanceContainer {
                    top: 54%;
                    right: 23%;
                }

                .addressBoxWidthContainer {
                    top: 90%;
                }

                input[name*="Width"] {
                    margin-right: 2.5rem;
                }


            }
        }
    }
    .bodyMargins {
        background-image: url("/static/documentTemplates/img/inhalt.svg");
    }
    .headerFooterMargins {
        background-image: url("/static/documentTemplates/img/kopf-fuss.svg");
    }
    .addressBoxMargins {
        background-image: url("/static/documentTemplates/img/mailing-address-box.svg");
    }
    .box-body .tab-content{
        padding: 0;
    }
    .flex-grow-5 {
        flex-grow: 5;
    }
    &.skin-dark {
        .bodyMargins {
            background-image: url("/static/documentTemplates/img/inhalt-dark.svg");
        }
        .headerFooterMargins {
            background-image: url("/static/documentTemplates/img/kopf-fuss-dark.svg");
        }
        .addressBoxMargins {
            background-image: url("/static/documentTemplates/img/mailing-address-box-dark.svg");
        }
    }
    #returnAddressHtml ~ .ck-editor {
        .ck-content .text-extreme-tiny {
            font-size: 0.5rem;
        }
    }
}