.grid-stack:empty {
    min-height: 100px;
}

.grid-stack .box {
    min-height   : 100%;
    margin-bottom: 0px;
}

.grid-stack>.grid-stack-item>.ui-resizable-handle {
    background-color: white;
    padding         : 10px;
    width           : 30px;
    height          : 30px;
    border          : 1px solid var(--primary);
    color           : white;
    border-radius   : 50%;
}