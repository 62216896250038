.register_link {
	color: var(--primary);
	text-decoration: none;
	cursor: pointer;
}

.register_choice_box {
	border: none;
	box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.10);
}

.register_control {
	margin: 0.9rem 0rem;
}

.ev_card_content {
	text-align: center;

	& p {
		font-size: 1.4rem;

		& .ev_card_headline {
			font-size: 1.6rem;
		}
	}
}

.ev_register_card_benefits_icon {
	width: 3rem !important;
	height: 3rem !important;
	color: var(--primary);
}

#ev_card_register4_licenseTablePrice {
	max-width: 32rem;
}

#breadCrumbDemoContainer .breadcrumb .breadcrumb-item.active {
	font-weight: bold;
}

#breadCrumbRegisterContainer .breadcrumb .breadcrumb-item.active {
	font-weight: bold;
}

#breadCrumbSetupContainer .breadcrumb .breadcrumb-item.active {
	font-weight: bold;
}

.breadcrumb-item.active::after {
	content: '';
	width: 80%;
	height: 7px;
	display: block;
	background-color: #23985d;
	border-radius: 5rem 5rem 0rem 0rem;
	margin-top: 15px;
	margin-bottom: -14px;
	margin-left: 10%;
	transition: all 0.3s ease-in-out;
}


@media screen and (max-width: 991px) {

	.breadcrumb-item.active::after {
		content: '';
		width: 0%;
		height: 0px;
	}
}

.ev_register_card_seal_icon img {
	width: 100%;
	height: auto;
}

.ev_register_card_seal_long {
	margin: auto;

}

.register-btn {
	text-decoration: none;
	padding: 5px 20px;
	margin: 0 5px;
	border-radius: 10px;
	background-color: #23985d;
	border: 1px solid #23985d;
	color: #ffffff;
	display: inline-block;
}

.navbar-brand-logo {
	font-size: 2.5rem;
}

.box-register {
	border: none;
}

.subpage-title {
	font-size: 35px;
	font-weight: 550;
}

.evTextHighlight {
	color: #23985d;
}

#registerButton:disabled {
    background-color: #6c757d !important;
	border-color: #6c757d !important;
}
