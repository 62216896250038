#appSettings {
    .tab-pane {
        padding: 0 30px;
    }

    .sortable {
        list-style-type: none;
        margin: 0;
        padding: 0;
        min-width: 30%;
        min-height: 34px;

        li {
            margin: 1px;
            padding: 0.4em;
            display:inline-block;
            vertical-align: top;
            background-color:white;
            border: 1px solid black;
            text-align: left
        }
    }
}

.list--collection ul {
    min-height: unset;
}

.list--collection ul li {
    display: inline-block;
    width: auto;
    margin-right: 10px;
}

.mailingPicture {
    width: 100%;
    border: 2px solid var(--primary);
}

.buttonMargin {
    margin:10px;
}

.marging-top {
    margin-top: 10px;
}

#easyVereinPlaceholder {
    margin: 15px;
}

#modalPlaceholderDiv {
    width: 900px;
}

.padding-top-5 {
    padding-top:5px;
}

.enabledOptionInputWarningLink {
    margin-right:5px;
    color: var(--warning);
}

.grabAbleElement {
    min-height: 3rem;
}

.templateName input {
    background-color: var(--bs-gray-200);
    color: var(--bs-gray-800);
    border: none;
}
