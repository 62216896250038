.dropzone {
    border: 1px solid var(--primary) !important;
    border-radius: $border-radius !important;
    display: flex !important;
    justify-content: center !important;
}

.dropAreaContainer.dragging .dropArea {
    --primary-a: 20%;
    background-color: rgba(var(--primary-rgb), var(--primary-a));
}

.dropAreaContainer.dragging .dropArea.dragging {
    --primary-a: 40%;
    background-color: rgba(var(--primary-rgb), var(--primary-a));
}

.dropAreaContainer.dragging .dropArea.dragging * {
    // Fixes flashing on dragleave
    pointer-events: none;
}

.dropArea {
    border        : 1px solid #86c5a6;
    padding       : 1em;
    border-radius : 1em;
    min-height    : 7em;
    margin-top    : 1em;
    display       : flex;
    flex-direction: column;
    text-align    : center;
    cursor        : pointer;
}

.dropAnimation {
    background-color: rgba(var(--primary-rgb), var(--primary-a));
    position: relative;
    animation-name: example;
    animation-duration: .4s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-timing-function: linear;
}
  
@keyframes example {
	0% { -webkit-transform: translate(1px, 1px) rotate(0deg); background-color: #86c5a6;}
	10% { -webkit-transform: translate(-1px, 0px) rotate(1deg); }
	40% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
	60% { -webkit-transform: translate(-1px, 1px) rotate(0deg); }
	80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
	100% { -webkit-transform: translate(1px, -1px) rotate(-1deg); background-color: rgba(var(--primary-rgb), var(--primary-a));}
}

.deleteFileCheckboxBorder {
    margin-top: 1em;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #86c5a6;
    border-radius: 1em;
    empty-cells: hide;
}

.deleteFileCheckboxBorder:empty {
    display: none;
}

.dropArea input {
    display: none;
}


.dropArea .dropAreaTitle {
    margin-top: auto;
    color: var(--primary);
}

.dropzone .dz-message .dz-button {
    background: none;
    color: var(--primary);
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
}