.page-header {
    margin   : 10px 0 20px 0;
    font-size: 22px;
}

.page-header>small {
    color     : #666;
    display   : block;
    margin-top: 5px;
}
