.box {
    --border: #{$card-border-width} solid #{$card-border-color};

    position: relative;
    border: var(--border);
    border-radius:$card-border-radius;
    margin-bottom: 1.5rem;
    width: 100%;
    box-shadow: $box-shadow-sm;
    background-color: var(--bs-body-bg);

    .box-body.collapse:not(.show) {
        display: none;

        ~ .box-footer {
            display: none;
            border-top: none;
        }

        ~ .box-header.with-border {
            border-bottom: none;
        }
    }

    .box-header {
        color: $gray-800;
        display : block;
        padding: $card-title-spacer-y $card-spacer-x;

        .box-title {
            margin: 0;
            font-size: var(--font-large);
        }

        &.with-border {
            border-bottom: var(--border);
        }
    }

    .box-body {
        padding: $card-spacer-y $card-spacer-x;
    }

    .box-footer {
        border-top: var(--border);
        padding: $card-spacer-y $card-spacer-x;
    }

    .tab-content {
        padding: $card-spacer-y $card-spacer-x;
    }

    & > .btn {
        border-radius: 0 0 $card-border-radius $card-border-radius;
    }
}