// Rounds top left corner of the first tab (when active)
.nav-tabs li:first-child .nav-link.active {
    border-top-left-radius: var(--bs-nav-tabs-border-radius) !important;
}

// Rounds top right corner of the last tab (when active)
.nav-tabs li:last-child .nav-link.active {
    border-top-right-radius: var(--bs-nav-tabs-border-radius) !important;
}

.nav-tabs .nav-link {
    // increases padding on both sides for wider tabs
    padding:  calc(var(--bs-nav-link-padding-y) * 2.5) calc(var(--bs-nav-link-padding-x) * 2) !important;
    // removes top round corners from all tabs
    border-radius: 0 !important;
}

// to get the active tab color (border-top)
.nav-tabs .nav-link.active {
    box-shadow: 0px 10px 0px -5px var(--primary) inset;
}
