#appSettingsFields {
    .customFieldsBody ul {
        li {
            display: inline-grid;
            div {
                background-color: var(--bs-body-bg);
            }
        }
        padding-left: 0rem !important;
    }

    #customFieldCollections, #selectionFieldOptions {
        list-style: none;
    }

    #selectionFieldOptions li {
        > ::before{
            content: "⠿";
            padding-top: 3.7%;
            padding-right: 2%;
            cursor: move;
        }
        display: ruby;
    }

    ul.customField_e {
        min-height: 1rem;
    }

    span[name="customFieldType"], span[name="customFieldDescription"] {
        color: var(--bs-gray-600)
    }

    .rotate {
        transform: rotateZ(180deg) !important;
    }

    span[name="collectionTitle"] input {
        background-color: var(--bs-gray-200);
        color: var(--bs-gray-800);
        border: none;
    }

    .customFieldsBox {
        background-color: var(--bs-gray-100);
    }

    .roundButton {
        width: 2.4rem;
        height: 2.4rem;
    }
}