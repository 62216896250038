.star-ratings {
    unicode-bidi: bidi-override;
    color       : #ccc;
    font-size   : 30px;
    position    : relative;
    margin      : 0;
    padding     : 0;

}

.fill-ratings {
    color   : var(--primary);
    padding : 0;
    position: absolute;
    z-index : 1;
    display : block;
    top     : 0;
    left    : 0;
    overflow: hidden;
}

.empty-ratings {
    padding: 0;
    display: block;
    z-index: 0;
}