
.list-builder {
    --lb-background: #{$dropdown-bg};
    --lb-color: #{$dropdown-color};
    --lb-border-color: #{$list-group-border-color};
    --lb-header-background: #{$primary};
    --lb-header-color: #{$dropdown-link-active-color};
    --lb-entry-background: var(--lb-background);
    --lb-entry-background-active: #{ $dropdown-link-active-bg};
    --lb-entry-color-active: #{$dropdown-link-active-color};
    --lb-entry-disabled-color: #{$dropdown-link-disabled-color};
    --lb-entry-focus-background: #{$dropdown-link-hover-bg};
    --lb-entry-focus-color: #{$dropdown-link-hover-color};
    --lb-entry-moved-background: var(--lb-entry-focus-background);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
        & {
            flex-direction: row;
        }
    }

    .list-builder-options,
    .list-builder-selection {
        min-width: 200px;
        width: 100%;
        flex-grow: 2;
        border: 1px solid var(--lb-border-color);
        background-color: var(--lb-background);
        color: var(--lb-color);

        @include media-breakpoint-up(md) {
            & {
                width: 40%;
            }
        }
    }

    .list-builder__header {
        background-color: var(--lb-header-background);
        color: var(--lb-header-color);
        padding: 4px;
    }

    .list-builder__search {
        border-radius: 0px;
        border: 0px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: var(--lb-border-color);

        &:focus,
        &:focus-visible,
        &:active {
            box-shadow: none;
            border-bottom-color: var(--lb-header-background);
        }
    }

    .list-builder__list {
        min-height: 200px;
        max-height: 50vh;
        overflow-y: auto;
    }

    .list-builder__entry {
        background-color: var(--lb-entry-background);
        width: 100%;
        font-weight: normal;
        border-block-end: 1px solid var(--lb-border-color);
        padding: 1rem;
        margin: 0rem;

        &:hover,
        &:focus {
            background-color: var(--lb-entry-focus-background);
            color: var(--lb-entry-focus-color);
        }
    }

    .list-builder__entry--moved {
        /* This style is use to indicated that an entry has been moved from one list to the other.
        It's used to show the user why this entry is shown, even if it shouldn't match any search query.
        */
        background-color: var(--lb-entry-moved-background);
        font-weight: bold;
    }


    .list-builder__entry.disabled,
    .list-builder__entry.disabled:hover {
        color: var(--lb-entry-disabled-color);
        font-style: italic;
    }

    input[type="checkbox"] {
        visibility: hidden;
        display: none;
    }

    input[type="checkbox"]:checked ~ .list-builder__entry {
        font-weight: bold;
        background-color: rgb(225, 225, 225);
        color: var(--lb-entry-color-active);
        background-color: var(--lb-entry-background-active);
    }

    .list-builder-actions {
        display: flex;
        flex-direction: row;
        margin: 1rem 0rem 1rem 0rem;
        justify-content: space-around;

        @include media-breakpoint-up(md) {
            & {
                flex-direction: column;
                justify-content: space-evenly;
                margin: 0rem 3rem 0rem 3rem;
            }
        }
    }

    .list-builder-action-button {
        font-size: 2.5rem;
        background-color: unset;
        margin: 0rem;
        border: none;
    }

    .list-builder-action-button {
        & svg {
            color: $link-color;
            &:hover,
            &:focus,
            &:active {
                color: $link-hover-color;
            }
        }
        .inline {
            display: none;
            @include media-breakpoint-up(md) {
                & {
                    display: block
                }
            }
        }
        .block {
            display: block;
            @include media-breakpoint-up(md) {
                & {
                    display: none;
                }
            }
        }
    }

    // Styles for different entries.
    .list-builder__entry--invoice {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(md) {
            & {
                grid-template-columns: 1fr 1fr;
            }
        }

        @include media-breakpoint-up(xxl) {
            & {
                grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
            }
        }
    }

    .billingAccountEntry {
        float: left;
        width: 50%;
    }
}