#appSettingsDocumentTemplates {
    #newDocumentTemplate {
        border-radius: 1rem;
    }
    .full-height {
        @include media-breakpoint-up(xxl) {
            min-height: calc(100vh - var(--main-header-height) - var(--main-footer-height) - 2rem);
        }
    }
    .card {
        border-radius: 1rem;
        border: 1px solid #00000026;


        a {
            color: inherit;
            text-decoration: none;
        }

        .card-body {
            padding-bottom: 0;
        }
        .card-footer {
            border-top: none;
            background-color: transparent;
        }
        .card-header {
            position: relative;
            border-bottom: none;
            background-color: $gray-100;
            padding-bottom: 0;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;

            &:hover {
                .overlay {
                    display: flex;
                }
                .dropdown-button {
                    color: white;
                }
            }

            .placeholderImage {
                height: 5.5rem;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("/static/img/letter.png");
            }

            .dropdown {
                position: absolute;
                right: 0;
                top: 0;

                .dropdown-button {
                    font-size: 1.3rem;
                    padding: 0.5rem 1rem;
                }
            }
            .overlay {
                display: none;
                background-color: #00000094;
                color: white;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
            }
        }
    }

    .templatePreview {
        border: 2px solid var(--primary);
        min-width: 420px;
        min-height: 420px;
        display: flex;
        align-items: center;
        justify-content: center;

        img + .loading {
            display: none;
        }

        img.hidden + .loading {
            display: block;
        }
    }

    .floating-textbox {
        width: 100%;
        border: none;
        resize: none;
    }

    .textarea-container {
        position: absolute;
        width: 50%;
        background-color: var(--bs-body-bg);
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
        z-index: 10;
    }
}

