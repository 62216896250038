/* LSB Export integration count modal */
#appSettingsIntegrations {
    #lsbCount {
        @media (max-width: 991px) {
            #lsbBTable {
                .row {
                    .lsb-min-width {
                        min-width: 8rem;
                    }
                }
            }
        }
        .lsbTable {
            overflow-y: hidden;
            .row {
                &.fw-bold, &:hover {
                    background-color: var(--table-header-default-color);
                    border-radius: .75rem;

                    div:first-of-type, .lsb-min-width {
                        background-color: var(--table-header-default-color) !important;
                    }
                    div:first-of-type:not(.lsb-min-width) {
                        border-radius: .75rem 0 0 .75rem;
                    }
                    .lsb-min-width:last-of-type {
                        border-radius: 0 .75rem .75rem 0;
                    }
                }

                .lsb-min-width {
                    min-width: 10rem;
                }
            }
        }
    }

    /* DOSB Export integration count modal */
    #dosb_count {
        @media (max-width: 991px) {
            #dosbBTable {
                .row {
                    min-width: 65rem;
                }
            }
        }
        .dosbTable {
            overflow-y: hidden;
            .row {
                &.fw-bold, &:hover {
                    background-color: var(--table-header-default-color) !important;
                }
            }
        }
    }
}