#appBookkeepingInvoices {
    .sortable {
        list-style-type: none;
        margin: 0;
        padding: 0;
        min-width: 30%;
        min-height: 34px;

        li {
            margin: 1px;
            padding: 0.4em;
            display:inline-block;
            vertical-align: top;
            background-color: var(--gray-900);
            color: var(--gray-200) !important;
            border: 1px solid black;
            text-align: left
        }
    }
}
