/* Passcreator Integration page */
.passcreator-reload-templates {
    display: flex;
    justify-content: flex-end;
}

.passcreator-centered {
    display: flex;
    justify-content: center;
}

.passcreator-template-list {
    margin-block: 2rem;
}

.passcreator-template-list-entry {
    display: flex;
    justify-content: space-between;
    margin-block-end: 1rem;
}

.form-mapping-template {
    display: none;
}

.passcrator-print-template-preview {
    margin-block: 1rem;
}

.passcrator-print-template-preview > img {
    border: 1px solid var(--primary);
}

#add_new_field {
    margin-bottom: 1rem;
}