#appSettingsInvoices {
    .arrow-down {
        width: 0;
        height: 0;

        border-left: 40px solid transparent;
        border-right: 40px solid transparent;

        border-top: 20px solid #23985D;

        margin-top: 20px;
    }

    .completed { opacity: 60%; }

    #connectInterfaceWarning {
        border-color: white;
    }

    #table {
        width: inherit;
    }

    #bookkeepingDonationSigningImage {
        padding: 2px;
        width: 25rem;
        height: auto;
    }

    #importInterfaceDiv {
        margin: .5rem 0;
    }

    #earliestBookingDate {
        text-align: justify;
    }

    #placeholderTable {
        table-layout: fixed;
        overflow: hidden;
        margin: 3px;
    }

    @media(max-width: 768px) {
        .desktopArrowDown {
            display: none;
        }
    }
    @media(min-width: 768px) {
        .mobileArrowDown {
            display: none !important;
        }
    }
}