// FIREFOX ( ::-moz- not working in firefox for some reason )
html {
    scrollbar-color: var(--bs-gray-500) var(--bs-gray-200) !important;
}

.box-body, .dropdown-menu, .timeline-content-invoices, #searchEasyVerein, #searchColumnsList, #memberListProfile, .tasksHolder {
    scrollbar-width: thin !important;
}


// CHROME / SAFARI
:root {
    --scrollbar-chrome-sm: 0.35rem;
}

::-webkit-scrollbar {
    width:  1rem !important;
    height:  1rem !important;
}

::-webkit-scrollbar-track {
    background: var(--bs-gray-100) !important;
}

::-webkit-scrollbar-thumb {
    background: var(--bs-gray-400) !important;
    border-radius: 1rem !important;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--bs-gray-500) !important;
}

// smaller scrollbar for some containers
.box-body::-webkit-scrollbar {
    width: var(--scrollbar-chrome-sm) !important;
    height: var(--scrollbar-chrome-sm) !important;
}

.timeline-content-invoices::-webkit-scrollbar {
    width: var(--scrollbar-chrome-sm) !important;
    height: var(--scrollbar-chrome-sm) !important;
}

.tasksHolder::-webkit-scrollbar {
    width: var(--scrollbar-chrome-sm) !important;
    height: var(--scrollbar-chrome-sm) !important;
}

.dropdown-menu::-webkit-scrollbar {
    width: var(--scrollbar-chrome-sm) !important;
    height: var(--scrollbar-chrome-sm) !important;
}

#memberListProfile::-webkit-scrollbar {
    width: var(--scrollbar-chrome-sm) !important;
    height: var(--scrollbar-chrome-sm) !important;
}

#searchEasyVerein::-webkit-scrollbar {
    width: var(--scrollbar-chrome-sm) !important;
    height: var(--scrollbar-chrome-sm) !important;
}

#searchColumnsList::-webkit-scrollbar {
    width: var(--scrollbar-chrome-sm) !important;
    height: var(--scrollbar-chrome-sm) !important;
}
