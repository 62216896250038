.cursor-normal {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-grab {
    cursor: grab;
}