/*=========================
	containing classes for litePages (pages which are outside app like login, register, npoRequest etc.)
===========================*/


.alert-lite {
    padding-left: 10%;
}

.litePageContainer {
	max-width: 87.5rem;
}

.litePageOuterContainer {
    background-image: url("/static/homepage/hexas.webp");
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    position: sticky;
    top: 0px;
}

.litePage-box {
    width: 360px;
    margin: 5% auto 0 auto;
}

.litePage-box-body {
    background: #fff;
    padding: 20px;
    color: var(--gray-500);
}

.litePage-box-body .form-control-feedback {
    color: var(--gray-500);
}

.litePage-box-msg {
    margin: 0;
    text-align: center;
    padding: 0 20px 20px 20px
}

.litePage-logo {
    font-size: 35px;
    text-align: center;
    margin-bottom: 25px;
    font-weight: 350;
}

.litePage-logo a {
    color: var(--gray-300);
}

.litePage-bg {
    background: var(--gray-700);
}

@media (max-width:768px) {
    .litePage-box {
        width: 90%;
        margin-top: 20px;
    }
}

.boxWithShadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

#imageWidth {
    width: 10rem;
}

#easyHeader {
    font-size: 4em;
    font-weight: light;
}

#vereinHeader {
    font-weight: bold;
}

.inputGroupBackground {
    background-color: var(--gray-700);
}

.loginLinks {
    font-size: 10pt;
}

.login-box {
    max-width: 480px;
}

#frontSalesChatButton {
    position: fixed;
    border: none;
    z-index: 99999999;
    color-scheme: normal;
    width: 64px;
    height: 64px;
    bottom: 30px;
    right: 25px;

    .fc-button {
        border-radius: 32px 32px;
        width: 64px;
        height: 64px;
        color: white;
        font-size: 2.7rem;
        padding-left: 14px;
        padding-top: 2px;

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }

        .loading {
            font-size: 1rem;
            padding-left: 4px;
        }
    }

    .fc-buttonWrapper {
        background-color: var(--primary);
        border: none;
        outline: none;
        cursor: pointer;
        position: relative;
        border-radius: 32px 32px;
        width: 64px;
        height: 64px;
        -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .2);
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .2);

        .has-chats-display {
            right: -10px;
            top: 10px;
        }
    }

}
