/*
 * This file contains rules for the base layout and rules that are shared between all pages.
 * Chances are you don't need to expand this file, but if you do please explain why in the connected issue or merge request.
 */

/*
 * Rules that are shared between all pages and too small to move into seperate files.
 */
// #region Shared rules
:root {
    --font-large: 17px;
    --font-normal: 14px;
    --font-small: 10px;
}

html {
    scroll-behavior: smooth;
}

html,
body {
    font-size : var(--font-normal);
}


body {
    box-sizing: border-box;
    height: 100vh;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight            : 400;
    padding-right          : 0 !important;
    color: var(--bs-gray-900);
    background-color: var(--bs-gray-100);

    &.large-font {
        font-size: var(--font-large);
    }
    &.collapse-main .main-menu-toggle {
        margin-left: 0.5rem;
        &:hover {
            background-color: var(--primary-darker) !important;
        }
    }
}

.hide,
.hidden {
    display: none !important;
}

.text-justify {
    text-align: justify;
}

.min-width-is-max-content {
    min-width: max-content;
}

.bg-gray {
    color           : #000;
    background-color: #d2d6de !important;
}

.flat {
    border-radius: 0 !important;
}

.text-sm {
    font-size: var(--font-small);
}

.ui-helper-hidden-accessible {
    border  : 0;
    clip    : rect(0 0 0 0);
    height  : 1px;
    margin  : -1px;
    overflow: hidden;
    padding : 0;
    position: absolute;
    width   : 1px;
}

.sort-highlight {
    background   : #f4f4f4;
    border       : 1px dashed #ddd;
    margin-bottom: 10px;
}

.category {
    padding    : 5px;
    width      : 50px;
    text-align : center;
    color      : white;
    font-weight: bold;
    text-decoration: none !important;
    display: inline-block;
    font-size: 1rem !important;
}

.noMarginForSmallScreens {
    @media (max-width: 767px) {
        margin: 0 !important;
    }
}

.headerScrollbarForMobile {
    overflow-x: auto !important;
}

.navbarToggleIcon {
    background-color: #51ae7f !important;
    height: 2.5rem;
    width: 2.5rem;
    &:hover {
        background-color: #0d5c35 !important;
    }
}

.tt-menu {
    // Styles the typeahead menu to look alike to a Bootstrap dropdown
    width: 100%;
    min-width: 500px;
    padding: $dropdown-padding-y;
    @include font-size($dropdown-font-size);
    color: $dropdown-color;
    background-color: $dropdown-bg;
    background-clip: padding-box;
    border: $dropdown-border-width solid $dropdown-border-color;
    @include border-radius($dropdown-border-radius);
    @include box-shadow($dropdown-box-shadow);
    z-index: 999999 !important;
}

.twitter-typeahead {
    width: 100%;
}

.img-circle {
    width : 145px;
    height: 145px;
    background-color: #ffffff;
}

.pagination .active {
    background-color: var(--primary);
    border-color: var(--primary);
}

.loader {
    position: absolute;
    width   : 200px;
    height  : 200px;
    top     : 0;
    bottom  : 0;
    left    : 0;
    right   : 0;
    margin  : auto;
}

blockquote {
    border-left-color: var(--primary);
}

:root {
    --main-sidebar-width: 240px;
    --main-header-height: 50px;
    --main-footer-height: 50px;
}
// #endregion Shared rules

/*
 * Rules that define the base layout:
 * - Static header
 * - Sidebar
 * - Content wrapper with alerts, page content and (sticky) footer
 */
// #region Base Layout
.main-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-height: 100px;
    z-index: 1040;
    min-height: var(--main-header-height);

    .navbar-brand a {
        color: color-contrast($primary);

        &:hover, &:focus {
            color: shade-color(color-contrast($primary), 20%);
        }
    }
    .navbar-brand {
        transition: width 0.3s;
        width: var(--main-sidebar-width);
        margin-right: 0px !important; // This gets changed by _navbar.scss
        background-color: var(--primary-darker);

        @media (max-width: 767px) {
            background-color: var(--primary);
        }

        > div {
            padding-top: 0.2rem; // This must be set so the brand does not get a weird border on its top and bottom
            padding-bottom: 0.2rem;
        }
    }

    .navbar-collapse .navbar-nav .nav-item .nav-link {
        line-height: 50px;
        height: 50px;
        &:not(#sidebar-toggle) {
            padding: 0 15px;
        }
    }

    .toggle-button {
        padding: 0.5rem;
    }

    .main-menu-toggle {
        border-radius: 0.5rem;
        margin-top: 0.1rem;
        text-decoration: none;
        &:hover {
            color: white !important;
        }

        &:focus {
            color: white !important;
        }
    }

    .above-navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav-link {
        transition: none !important;
    }


    #sidebar-toggle svg {
        font-size: 1.1rem;
    }

    #downloadIcon {
        border-radius: 0.5rem;
        padding: 0.5rem;
        &:hover {
            background-color: var(--primary-darker);
        }
    }
}

.main-sidebar {
    --background-color: #222d32;
    --header-font-color: #6D8C9C;
    --header-background-color: #1a2226;
    --element-font-color: #b8c7ce;
    --element-background-active-color: #1e282c;
    --link-font-color: white;
    --collapse-background: #2c3b41;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1030;
    pointer-events: none;


    #headerSearchbar {
        background-color: var(--collapse-background);
        border-color: transparent;
        color: var(--link-font-color);

        &:focus, &:focus-within {
            border-color: transparent;
        }

        & ~ label {
            color: var(--link-font-color);

            & > i, & > svg {
                color: var(--link-font-color);
            }
            &:active, &:focus, &:focus-within {
                color: var(--element-font-color);

                & > i, & > svg {
                    color: var(--element-font-color);
                }
            }
        }
    }

    #searchEasyVerein {
        max-width: max-content;
        min-width: 100%;
        overflow-y: scroll;
        max-height: 1200%;

        a {
            color: $dropdown-link-color;

            &:hover, &:focus {
                color: $dropdown-link-hover-color;
                background-color: $dropdown-link-hover-bg;
            }

            &:active {
                color: $dropdown-link-active-color;
                background-color: $dropdown-link-active-bg;
            }
        }
    }

    & .sidebar {
        width: var(--main-sidebar-width);
        padding-top: var(--main-header-height);
        transition: transform .3s ease-in-out;
        background-color: var(--background-color);
        pointer-events: auto;
        height: 100%;

        & .sidebar-header {
            border-bottom: 1px solid var(--header-background-color);
            a:not(.dropdown-item) {
                color: var(--header-font-color);

                &:hover, &:focus {
                    color: var(--link-font-color);
                }
            }
            & hr {
                // Making the hr filling the space completly
                margin-left: -1rem;
                margin-right: -1rem;
                color: var(--bs-gray-500);
            }
        }

        & .sidebar-menu-container {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: thin;
            scrollbar-color: transparent transparent; /* Initial transparent */
        }

        & .sidebar-menu-container:hover {
            scrollbar-color: hsl(0, 0%, 60%) transparent; /* Visible when hovering */
            scrollbar-width: thin;
        }

        & .sidebar-menu-container::-webkit-scrollbar {
            display: none; // hide the scrollbar for safari
        }

        & .sidebar-menu {
            position: relative;
            padding-left: 0;

            & > .header {
                color: var(--header-font-color);
                background-color: var(--header-background-color);
                overflow: hidden;
                text-overflow: clip;
                font-weight: bold;
                padding: 1rem;
                font-size: 0.8rem;
            }

            & hr {
                margin: 0rem 0;
                color: var(--bs-gray-500);
            }

            & > li, > div {
                margin: 0;
                padding: 0;
                border-left: 5px solid transparent;

                &.active {
                    color: var(--link-font-color);
                    background: var(--element-background-active-color);
                    border-left-color: var(--primary);
                    text-decoration: none;
                }

                &:hover:not(.header) {
                    border-left-color: var(--primary);
                }

                & > a {
                    color: var(--element-font-color);
                    padding: 12px 5px 12px 15px;
                    display: block;

                    &:hover, &:active, &:focus {
                        color: var(--link-font-color);
                        background: var(--element-background-active-color);
                        text-decoration: none;
                    }

                    --arrow-transition: transform 0.3s linear;
                    &[aria-expanded="false"] {
                        & .arrow-left {
                            display: block;
                            transform: rotate(0deg);
                            transition: var(--arrow-transition);
                        }
                    }

                    &[aria-expanded="true"] {
                        & .arrow-left {
                            transform: rotate(-90deg);
                            transition: var(--arrow-transition);
                        }
                    }
                }

                & > .collapse,
                & > .collapsing {
                    background-color: var(--collapse-background);

                    & ul {
                        list-style: none;
                        padding-left: 0px;

                        & li {
                            a {
                                color: var(--element-font-color);
                                padding: 5px 5px 5px 15px;
                                display: block;

                                &:hover, &:active, &:focus, &.active {
                                    color: var(--link-font-color);
                                }
                            }
                            &.active a {
                                color: var(--link-font-color);
                            }
                        }
                    }

                }
            }
        }
    }

    & .overlay {
        background-color: $modal-backdrop-bg;
        opacity: $modal-backdrop-opacity;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: opacity .1s linear;
        pointer-events: auto;
        z-index: -1;
    }

    @include media-breakpoint-up(md) {
        & .overlay {
            display: none;
            pointer-events: none;
        }
    }

}

.main-header, .main-sidebar {
    a {
        text-decoration: none;
    }
}



#content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between; // Makes the footer "stick" to the bottom
    margin-left: 0;
    transition: margin-left .3s ease-in-out;
    position: relative;
    top: var(--main-header-height);
    min-height: calc(100vh - var(--main-header-height));
    background-color: var(--bs-gray-200);

    @include media-breakpoint-up(md) {
        & {
            margin-left: var(--main-sidebar-width);
        }
    }
}

@for $i from 1 through 15 {
    .bg.bg-#{$i} {
        background: linear-gradient(rgb(236, 240, 245, 0.75), rgb(236, 240, 245, 0.85)), url('/static/img/background/bg-#{$i}.jpg');
        background-size : cover !important;
        background-attachment: fixed !important;
    }
}

.main-footer {
    color: var(--bs-gray-600);
    padding: 0 5.5em 0 15px;
    min-height: var(--main-footer-height);
    display: flex;
    align-items: center;
    text-align: center;

    @media (max-width: 600px) {
        flex-direction: column;

        > div {
            display: grid !important;
        }
        .footer-language {
            order: 0;
        }
        .footer-links {
            order: 1;
        }
        .vline {
            display: none;
        }
    }

    @media (min-width: 600px) {
        flex-direction: row;
        .footer-language {
            order: 1;
        }
        .footer-links {
            order: 0;
        }
        .vline {
            display: inline;
        }
    }
    .footer-appButtons {
        margin-left: 0;
        margin-right: auto;
        display: inline-block;
        a {
            text-decoration: none;
        }
        img {
            display: none;
        }
        .appIcon {
            display: inline;
        }
        @media (min-width: 1430px) {
            margin-left: auto;
            margin-right: .2rem;
            img {
                display: inline-block;
            }
            .appIcon {
                display: none;
            }
        }
    }

    #changeLanguageDropUp > button {
        --bs-btn-border-color: transparent;
    }

    .footer-links > a {
        color: var(--bs-gray-600);
    }
}

@include media-breakpoint-up(sm) {
    .content {
        padding: 1rem;
    }
}

.show-on-collapse {
    display: none;
}

.main-sidebar {
    & .sidebar {
        transition: width 0.3s;
    }

    & .deCollapseForSearch {
        background-color: var(--bs-gray-400);
    }
}

.collapse-main {
    & .main-sidebar {
        & .sidebar {
            width: 4.5rem;
        }
        & .overlay {
            opacity: 0;
            pointer-events: none;
        }
    }

    @include media-breakpoint-up(md) {
        #content-wrapper {
            margin-left: 4.5rem;
        }
    }

    & .hide-on-collapse {
        display: none !important;
    }

    & .show-on-collapse {
        display: initial !important;
    }

    & .navbar-brand {
        width: 4.5rem; // Must be the same as for the sidebar
    }

    #mainNavbarToggler {
        margin-left: 2.5rem;
    }

    .sidebar-menu {
        li, div {
            border-right: 5px solid transparent;
        }
        a:not(.dropdown-item) {
            padding: 12px 5px 12px 0px !important;
            text-align: center;
        }
    }
}

.collapse-main-mobile {
    @include media-breakpoint-down(md) {
    & .main-sidebar {
        & .sidebar {
            transform: translate(calc(var(--main-sidebar-width) * -1), 0px);
        }
        & .overlay {
            opacity: 0;
            pointer-events: none;
        }

        #content-wrapper {
            margin-left: 0;
        }
    }
}
}

.alerts .alert {
    border-radius: 0;
    margin-bottom: 0;
}

.tab-content .tabDockAlert {
    margin: -1rem -1rem 1rem -1rem !important; // reverts tab-content padding
    border: unset !important;
}

.user-panel {
    padding-left : 1rem;
    padding-right : 1rem;
    padding-top : 1rem;
    padding-bottom : 0.5rem;

    & img {
        height: auto;
        height: 35px;
        width: 100%;
        width: 35px;
        max-width: 45px;
    }
}

// For the memberSearch warning triangle if the user has no member/addess privileges
.start-97-5 {
    left: 97.5% !important;
}

.hideOverflowEllipsis, .select-us .dropdown-item {
    text-overflow: ellipsis;
    overflow: hidden;
}

.hideOverflow {
    overflow: hidden;
}

// Making the custom filter dropdown scrollable to support a big amount of custom filters
.customFilterDropdown {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}
// #endregion Base Layout

.disabledLink {
    pointer-events: none !important;
}

#mainNavbarToggler .nav-item:not(.nav-search, #flashDiv, #supportDataDiv) {
    border-left: 1px solid var(--primary-darker) !important;
    transition: background-color 0.5s ease;

    &:hover {
        background: var(--primary-darker);
    }
}

.mainNavbarTogglerButton {
    @media (max-width: 47.938rem) {
        margin-right: 0.625rem !important;
        background-color: #51ae7f !important;
    }
}

.main-header .navbar {
    @media (max-width: 767px) {
        background-color: var(--primary) !important;
    }
}

.textSubtleForeground {
    color: var(--text-foreground-subtle);
}

.openCustomFieldRequestsDot {
    width: 0.7rem;
    background-color: var(--bs-warning);
    display: inline-block;
    height: 0.7rem;
    border-radius: 1rem;
    margin-left: -1rem;
}

.border-dashed {
    border-style: dashed !important;
}

.border-dotted {
    border-style: dotted !important;
}

.alert-button {
    color: var(--bs-alert-link-color);
    border-color: var(--bs-alert-link-color);
}

.groupsAddDropDown {
    min-width: 400px !important;
}

.groupsAddDropDownBody {
    max-height: 25rem;
    .btn.active {
        border-width: 0;
    }
}

.groupsAddDropDownBtn {
	padding    : 0.75px 18px;
    text-align : center;
    color      : var(--primary) !important;
    text-decoration: none !important;
    display: inline-block;
    font-size: 1rem !important;
	border: 1px dashed var(--primary) !important;
	background: unset;
	white-space: nowrap;
}

.groupsAddDropDownBtn:hover, .groupsAddDropDownBtn.show {
	color      : #fff !important;
	background: var(--primary) !important;
}


.useWhitespace {
    white-space: pre-line;
}

.bg-disabled {
    td, a {
        color: gray !important;
    }
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

#searchColumnsList {
    .row {
        margin-left: 0 !important;
    }
    label {
        white-space: wrap;
        padding-right: var(--bs-dropdown-item-padding-x);
    }
}

/* This exists in BS5 but we have an older version */
.pl-0 {
    padding-left: 0 !important;
}

/* visually hidden title for screenreaders (WCAG2.0) not really pretty but works */
.hidden-title {
    position: absolute;
    left: -10000rem;
    top: auto;
    width: 0rem;
    height: 0rem;
    overflow: hidden;
}
