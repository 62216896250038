.btn-social[disabled] > :first-child { border-right: unset !important; }

.invoiceAmount {
	text-decoration-color: LightCoral;
	text-decoration-line: underline;
	text-decoration-style: double;
}

.priceTable { border-top: 2px solid black; }
.seperator { border-top: 2px dotted gray; }

.tab-pane h3, .tab-pane h4 {
	border-bottom: unset;
	color: inherit;
}

.top-5 { top: 5px !important; }

input.warning {
	--range-slider-accent-color: red !important;
	background-color: red !important;
}

#infoLink {
	text-decoration: underline;
	font-weight: bold;
	cursor: pointer;
}

/*
special Styling for input ranges in dynamicLicenseBooking
*/
.dynamicLicenseBox input[type='range'] {
	-webkit-appearance: none;
	accent-color: var(--primary);

	&::-webkit-progress-value {
		-webkit-appearance: none;
		background-color: var(--primary);
	}

	&::-moz-range-progress {
		background-color: var(--primary);
	}

}
