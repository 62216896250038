
.locationsButtonGroup {
    min-width: 50px !important;
}

.locationsDetailsImage {
    width: 80px;
    height: 80px;
    margin-right: 20px;
}

.locationsDetailsBox {
    padding: 10px;
}

.mapDiv {
    width: 100%;
    height: 300px;
}

#mapdiv2 {
    width: 100%;
    height: 38rem;
}

#maphiddenDiv {
    width: calc(100% - 4rem);
    height: 38rem;
    z-index: 99;
    background-color: var(--bs-gray-300);
}

#drafts {
    z-index: 99;
}

#locationPicture {
    height: 4.2rem !important;
    width: auto;
}

#locationReservationPicture {
    height: 3rem !important;
    width: auto;
}

.reservationInfo {
    height: 3rem;
    background-color: var(--bs-gray-200);
    border-radius: 0.4rem;
    padding: 0.2rem 0.4rem 0.2rem 0.7rem;
}

.reservationInfo_label {
    opacity: 50%;
    font-size: 70%;
}

.reservationInfo_time, .reservationInfo_location {
    font-size: 95%;
}

.reservationInfo_eventLink {
    background-color: var(--bs-gray-400);
    border-radius: 2rem;
    height: 2.4rem;
    width: 2.4rem;
}

.reservationsLocationListCollapse {
    position: absolute;
}

.dataTables_scrollBody {
    min-height: 13rem;
}