@import './bs-overrides.scss';
@import './layout.scss';
@import './boxWidget.scss';
@import './gridStack.scss';
@import './utils/ckeditor.scss';
@import './utils/droparea.scss';
@import './utils/loader.scss';
@import './utils/productsList.scss';
@import './utils/eyeCatchers.scss';
@import './utils/fileManagement.scss';
@import './utils/imagePicker.scss';
@import './utils/highlightElement.scss';
@import './utils/licenseChooser.scss';
@import './utils/dynamicLicenseBooking.scss';
@import './utils/boxWidget.scss';
@import './utils/alerts.scss';
@import './utils/image.scss';
@import './utils/hint.scss';
@import './utils/chatNotification.scss';
@import './tables.scss';
@import './textarea.scss';
@import './utils/cursor.scss';
@import 'ol/ol.css';
@import './navTabs.scss';
@import './sweetalert.scss';
@import 'app';
@import 'admin';
@import './queryBuilder.scss';
@import './demo-upgrade.scss';
@import './utils/taskQueue.scss';
@import './progressbar.scss';
@import './listBuilder.scss';
@import './confirmEmailForm.scss';
@import './projectSelect.scss';
@import './forum.scss';
@import './community.scss';
@import 'pluginsOverrides';
@import './scrollbars.scss';
@import './searchbar.scss';
@import './ckeditorTableStyles.scss';

@import 'dropzone/src/basic.scss';
@import 'dropzone/src/dropzone.scss';
@import 'jQuery-QueryBuilder/src/scss/default.scss';
@import '../../node_modules/vanillajs-datepicker/sass/datepicker.scss';
@import 'datatables.net-bs5/css/dataTables.bootstrap5.css';