#appSettingsGroups {
    .operatorDescription {
        display     : flex;
        align-items : baseline;
        gap: 0.5rem;
        margin: 1rem;

        pre {
            margin: 0;
        }
    }

    pre.inline {
        margin: 0;
        display: inline;
        padding: 0 3px;
    }
}

@import '../../utils/orderingArrows.scss';

.chosen-container-multi .chosen-choices {
	padding-left: 12px;
	border: 1px solid #ccc;
	font-size: 14px;
}

.custom-group-color:hover {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #91cdaf;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(35, 154, 94, 0.25);
}
