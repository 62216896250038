.green-pattern {
    --color-start: rgba(var(--bs-primary-rgb), 0.75);
    --color-end: rgba(var(--bs-primary-rgb), 0.95);

    position: absolute;
    background: linear-gradient(135deg, var(--color-start), var(--color-end)), url('/static/img/slider.jpg') no-repeat top center;
    background-size: cover;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: -1;
}