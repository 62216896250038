#appProtocol {
    .listBoxShadow {
        margin-bottom: 0.6rem;
        padding: 0.9rem !important;
        border-radius: 0.4rem !important;
        border-bottom: 0.06rem solid #f4f4f4;
        box-shadow: 0rem 0rem 0.19rem 0.19rem rgba(209, 209, 209, 0.2) !important;
    }

    ul#protocolFileList {
        list-style-type: none;
        margin-left: -0.6rem;
        padding: 0;
    }

    .items-list {
        border-bottom: none;
    }

    .products-list.product-list-in-box .listBoxShadow:last-of-type {
        border-bottom: none;
    }

    .listBoxShadow:hover {
        box-shadow: 0rem 0rem 0.19rem 0.19rem rgba(34, 153, 94, 0.2) !important;
    }

    .taskLabel {
        font-size: 0.85rem;
    }

    .showMoreActionsLink::after {
        margin-right: 0.255rem;
    }

    .skin-dark {
        .listBoxShadow {
        margin-bottom: 0.6rem;
        padding: 0.9rem !important;
        border-radius: 0.4rem !important;
        border-bottom: 0.06rem solid #3a3a3a !important;
        box-shadow: 0rem 0rem 0.19rem 0.19rem rgba(63, 63, 63, 0.2) !important;
        }

        .products-list.product-list-in-box .listBoxShadow:last-of-type {
        border-bottom: none;
        }

        .listBoxShadow:hover {
        box-shadow: 0rem 0rem 0.19rem 0.19rem rgba(34, 153, 94, 0.2) !important;
        }
    }

    .marked {
        box-shadow: 0rem 0rem 0.19rem 0.19rem rgba(34, 153, 94, 0.2) !important;
        border-radius: 0.3rem;
        padding: 0.3rem;
        margin-bottom: 1rem;
    }

    .dropArea {
        padding: 0 !important;
        border: none !important;
        margin-top: 0 !important;
        flex-direction: row !important;
        height: 100% !important;
    }

    .dropArea .dropAreaTitle {
        margin: initial !important;
    }

    .dropArea .border-dashed:hover .dropAreaTitle {
        color: #fff !important;
    }

    #protocolUploadList {
        overflow-y: scroll;
        max-height: 24.8rem;
    }

    .card {
        border-radius: 1rem;
        border: 1px solid #00000026;
        height: 100% !important;


        a {
            color: inherit;
            text-decoration: none;
        }

        .card-body {
            padding-bottom: 0;
        }
        .card-footer {
            border-top: none;
            background-color: transparent;
        }
        .card-header {
            position: relative;
            border-bottom: none;
            background-color: $gray-100;
            padding: 0;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;

            &:hover {
                .overlay {
                    display: flex;
                    z-index: 72; // Otherwise the blur thumbnail would be above it
                }
                .dropdown {
                    z-index: 73;
                }
                .dropdown-button {
                    color: white;
                }
                .thumbnail {
                    filter: blur(5px);
                    -webkit-filter: blur(5px);
                }
                .thumbnailContainer {
                    overflow: hidden;
                }
            }

            .thumbnailContainer {
                width: 100%;
                height: 100%;
                padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
                padding-bottom: 0;
            }

            .thumbnail {
                height: 5.5rem;
                background-repeat: no-repeat;
                background-position: center;
            }

            .placeholderImage {
                height: 5.5rem;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("/static/img/letter.png");
            }

            .dropdown {
                position: absolute;
                right: 0;
                top: 0;

                .dropdown-button {
                    font-size: 1.3rem;
                    padding: 0.5rem 1rem;
                }
            }
            .overlay {
                display: none;
                background-color: #00000094;
                color: white;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
            }
        }
    }
}
