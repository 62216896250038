.highlightElement {
    animation-duration: 1s;
    animation-iteration-count: 3;
    animation-name: highlightElementAnimation;
    transition-timing-function: linear;
    --animation-color-at-0: var(--gray-700);
    --animation-color-at-50: var(--primary);
    --animation-color-at-100: var(--gray-700);

    &.highlightElement--danger {
        --animation-color-at-50: var(--danger);
    }
}

@keyframes highlightElementAnimation {
    0% { color: var(--animation-color-at-0); }
    50% { color: var(--animation-color-at-50); }
    100% { color: var(--animation-color-at-100); }
}
