.loader {
    position: absolute;
    width: 12.5rem;
    height: 12.5rem;
    top: 0rem;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    margin: auto;
}

.loaderitem {
    width: 3.125rem;
    height: 3.125rem;
    position: absolute;
}

.loaderitem-1 {
    background-color: var(--primary);
    top: 0rem;
    left: 0rem;
    z-index: 1;
    animation: loaderitem-1_move 2.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.loaderitem-2 {
    background-color: var(--primary);
    top: 0rem;
    right: 0rem;
    animation: loaderitem-2_move 2.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.loaderitem-3 {
    background-color: var(--primary);
    bottom: 0rem;
    right: 0rem;
    z-index: 1;
    animation: loaderitem-3_move 2.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.loaderitem-4 {
    background-color: var(--primary);
    bottom: 0rem;
    left: 0rem;
    animation: loaderitem-4_move 2.8s cubic-bezier(.6,.01,.4,1) infinite;
}

@keyframes loaderitem-1_move {
    0%, 100% {transform: translate(0rem, 0rem)}
    25% {transform: translate(0rem, 6.25rem)}
    50% {transform: translate(6.25rem, 6.25rem)}
    75% {transform: translate(6.25rem, 0rem)}
}

@keyframes loaderitem-2_move {
    0%, 100% {transform: translate(0rem, 0rem)}
    25% {transform: translate(-6.25rem, 0rem)}
    50% {transform: translate(-6.25rem, 6.25rem)}
    75% {transform: translate(0rem, 6.25rem)}
}

@keyframes loaderitem-3_move {
    0%, 100% {transform: translate(0rem, 0rem)}
    25% {transform: translate(0rem, -6.25rem)}
    50% {transform: translate(-6.25rem, -6.25rem)}
    75% {transform: translate(-6.25rem, 0rem)}
}

@keyframes loaderitem-4_move {
    0%, 100% {transform: translate(0rem, 0rem)}
    25% {transform: translate(6.25rem, 0rem)}
    50% {transform: translate(6.25rem, -6.25rem)}
    75% {transform: translate(0rem, -6.25rem)}
}
