#adminStatistics {

    & .activeOption {
        color: var(--primary) !important;
        opacity: 0.5 !important;
    }

    & .activeOption:hover {
        color: var(--primary) !important;
        opacity: 0.8 !important;
    }

    & .type { margin-left: 10px; }

    & .month-box-body {
        min-height: 200px;
    }

    & .close {
        cursor: pointer;
    }

}
