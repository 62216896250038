#demo-upgrade-dropdown {
    border-top-left-radius: 0px;
    padding-top: 0;
    border-top-right-radius: 0px;

    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 3rem;
        color: var(--gray-900);
        background-color: var(--primary);

        .upgrade-icon {
            font-size: 500%;
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
    }
}