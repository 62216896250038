.link-table {
    $value: $dark;
    color: $value;
    text-decoration: none;
    font-weight: bold;

    @if $link-shade-percentage != 0 {
        &:hover,
        &:focus {
        color: if(color-contrast($value) == $color-contrast-light, shade-color($value, $link-shade-percentage), tint-color($value, $link-shade-percentage));
        }
    }
}

.sticky-actions-bar {
    position: sticky;
    right: 0;
    min-width: 5.5rem;
    top: auto;
}

td.sticky-actions-bar-shadow:last-child:before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: 0px;
    left: -15px;
    width: 15px;
    box-shadow: -7px 0 6px -8px rgba(0, 0, 0, 0.2) inset;
}

// Overwriting the background-color: var(--bs-table-bg) from _tables.scss which seems to come from nowhere
table .bg-primary > td {
    background-color: inherit;
}

thead, tbody, tfoot, tr, td, th {
    border: unset;
}

.table > :not(caption) > * > * {
    padding: 1rem !important;
}

div.dataTables_scrollBody > table.table.dataTable {
    thead, tfoot {
        th {
            padding-top: 0 !important;
            /* This somehow prevents unnecessary vertical scrollbars from showing that are showing when the value is set to 0 */
            padding-bottom: 1px !important;
        }
    }
}

// additional styles for table-hover
.table-hover > tbody > tr:hover > *:first-child {
    border-radius: 0.75rem 0 0 0.75rem !important;
}
.table-hover > tbody > tr:hover > *:last-child {
    border-radius: 0 0.75rem 0.75rem 0 !important;
}

// styles only for datatables
.dataTables_paginate  {
    text-align: left !important;

    & .pagination {
        justify-content: flex-start !important;
    }
}

div.dataTables_processing {
    z-index: 9999 !important;
}

div.dataTables_processing > div:last-child > div {
    background-color: var(--primary) !important;
}

.dataTables_scrollHead, tr.stickyTableHead, div.stickyTableHead {
    position: sticky !important;
    top: 4rem;
    z-index: 1;
}

.stickyTableHead{
    position: sticky !important;
    top: 8rem;
    z-index: 1;
}

.dataTables_scrollHead th {
    background: var(--table-header-default-color) !important;
    padding: 1rem 0.75rem 1rem 1.25rem !important;
}

.dataTables_scrollHead th:first-child {
    border-radius: 0.75rem 0 0 0.75rem !important;
}

.dataTables_scrollHead th:last-child {
    border-radius: 0 0.75rem 0.75rem 0 !important;
}

// styles only for tables which are not datatables and not sutpid tables and not the fullCalendar
:not(.dataTables_scrollBody, .table-no-header-wrapper, .fc-scroller, .fc-view) > table > tbody > tr > th,
:not(.dataTables_scrollBody, .table-no-header-wrapper, .fc-scroller, .fc-view) > table > thead > tr > th {
    background: var(--table-header-default-color) !important;
    padding: 1rem 0.75rem 1rem 1.25rem !important;
    vertical-align: middle !important;
}

:not(.dataTables_scrollBody, .table-no-header-wrapper, .fc-scroller, .fc-view) > table > tbody > tr.headerStylingTransparent > th,
:not(.dataTables_scrollBody, .table-no-header-wrapper, .fc-scroller, .fc-view) > table > thead > tr.headerStylingTransparent > th {
    background-color: transparent !important;
}

:not(.dataTables_scrollBody, .table-no-header-wrapper, .fc-scroller, .fc-view) > table > tbody > tr > th:first-child,
:not(.dataTables_scrollBody, .table-no-header-wrapper, .fc-scroller, .fc-view) > table > thead > tr > th:first-child {
    border-radius: 0.75rem 0 0 0.75rem !important;
}

:not(.dataTables_scrollBody, .table-no-header-wrapper, .fc-scroller, .fc-view) > table > tbody > tr > th:last-child,
:not(.dataTables_scrollBody, .table-no-header-wrapper, .fc-scroller, .fc-view) > table > thead > tr > th:last-child {
    border-radius: 0 0.75rem 0.75rem 0 !important;
}

// styles for listings (fake tables)
.listHeaderRow,
.list-group-item-action:hover {
    background: var(--table-header-default-color) !important;
    border-radius: 0.75rem !important;
}

// additional fix for fullCalendar (cause this shit calendar is made of nested tables)
.fc-view table th {
    border: 1px solid var(--bs-gray-400) !important;
}

.goToTablePageInput {
    border: none !important;
    outline: none !important;
    border-bottom: 1px solid var(--bs-gray-900) !important;
    width: 3rem !important;
    text-align: center !important;
    background-color: inherit;
    color: var(--bs-gray-900);
}

.goToTablePage {
    float: right !important;
    white-space: nowrap !important;
}

.massActionTable--selectable {
    td * {
        pointer-events: none !important;
    }
    tbody tr {
        cursor: pointer;
    }
}

#usersTable tr.bg-primary {
    a[id^=addGroup].rounded-pill {
        border-color: #fff !important;
        color: #fff !important;
    }

    a[id^=addGroup].rounded-pill:hover {
        border-color: #fff !important;
    }
}

thead{
    --bs-table-bg: transparent;
}

.min-width-100 {
    min-width: 100px;
}

.min-width-150 {
    min-width: 150px;
}

.table-group-width {
    min-width: 12.0rem !important;
    width: 12.0rem !important;
}

#searchColumnsList {
    max-width: max-content;
    min-width: 100%;
    overflow-y: scroll;
    max-height: 80vh;
    direction: rtl;

    #searchColumns {
        direction: ltr;
    }
}

@media (max-width: 600px) {
    #searchColumnsList {
        max-height: 50vh;
    }
}
