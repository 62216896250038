// FullCalendar
.fc {
    max-height: calc(100vh - 150px) !important;
}

.fc.fc-theme-bootstrap5-ev  {
    // Official styles from https://github.com/fullcalendar/fullcalendar/blob/master/packages/bootstrap5/src/main.css
    & a:not([href]) {
        color: inherit;
        text-decoration: inherit;
    }

    & .fc-list,
    & .fc-scrollgrid,
    & td,
    & th {
        border: 1px solid var(--bs-gray-400);
    }

    & .fc-scrollgrid {
        border-right-width: 0;
        border-bottom-width: 0;
        border-width: 0;
    }

    & .fc-list-event td {
        border-left: 0;
        border-right: 0;
    }


    // Custom changes
    --fc-list-event-hover-bg-color: $primary-btn-hover-bg; // Fixes the hover bug in the list view

    .fc-col-header {
        border-style: hidden;
    }

    .fc-event {
        // Keep font color contrasting to the background
        color: color-contrast($primary) !important;
        &:not(.fc-list-event) {
            width: -webkit-fill-available;
            width: -moz-available;
        }
        & .fc-list-event-dot,
        & .fc-daygrid-event-dot {
            // Add an outline to make the colored dot stand out, if it is close to the primary color
            outline: 1px solid color-contrast($primary);
            display: inline-block;
            & ~ .fc-event-time {
                display: inline-block;
            }
        }
    }
    .fc-col-header-cell {
        font-size: large;
        font-weight: bold !important;
    }

    .table-bordered td{
        border-style: none !important;
    }

    @media only screen and (max-width: 450px) {
        height: 50em;
    }

    .fc-header-toolbar {
        .btn-group {
            margin: ($spacer * 0.3) 0 ($spacer * 0.3) 0;
        }
        > :nth-child(1) {
            text-align: left;
            .btn-group {
                margin-right: 0.75em;
            }
            .fc-today-button {
                margin-left: 0;
            }
        }
        > :nth-child(2) {
            text-align: center;
        }
        > :nth-child(3) {
            text-align: right;
        }
    }

    .fc-event-main{
        overflow: clip;
        text-wrap: wrap;
        justify-content: center;
        padding: 0;
    }

    .fc-event-time{
        min-width: fit-content;
    }

    .durationSmallerThan45Minutes{
        padding: 0;
        font-size: 0.7rem;
    }

    .similar-start-event, .overlapped-event{
        width: calc(50% - 5px) !important;
    }

}

.fc-theme-bootstrap5-ev-shaded {
    background-color: var(--bs-gray-200);
}

.calendarBadge {
    border-style: solid;
    border-width: 2px;
}