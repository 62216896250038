#tableSettings {
    .sortable {
        list-style-type: none;
        margin: 0;
        padding: 0;
        min-width: 30%;
        min-height: 34px;

        li {
            margin: 5px 1px 5px 1px;
            padding: 0.4em;
            display:inline-block;
            vertical-align: top;
            background-color: var(--gray-900);
            color: var(--gray-200) !important;
            border: 1px solid hsl(0, 0%, 70%);
            border-radius: 5px;
            text-align: left
        }

        .static {
            background-color: var(--bs-gray-200) !important;
            border-color: var(--bs-gray-400) !important;
        }
    }


    #tableFields li {
        margin: 1px;
        padding: 0.4em;
        display:inline-block;
        vertical-align: top;
        background-color: hsla(150, 63%, 37%, 0.3);
        color: var(--gray-200) !important;
        border: 0.06rem solid hsla(150, 63%, 37%, 0.3);
        border-radius: 5px;
        text-align: left;
    }
}