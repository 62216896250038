// TODO: I'm unhappy with this element, there's basically no structure in how this is used.
.products-list {
    list-style: none;
    margin: 0;
    padding: 0;

    .item {
        border-radius: 3px;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        padding: 10px 0;

        &::after {
            padding-bottom: 2px;
            clear: both;
        }

        .product-img {
            float: left;

            img {
                width: 50px;
                height: 50px;
            }
        }

        .product-info {
            margin-left: 60px;
        }

        .product-title {
            font-weight: bold;
        }

        .product-description {
            display      : block;
            color        : $gray-900;
            text-overflow: ellipsis;
        }
    }

    &.product-list-in-box .item {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
        border-bottom: 1px solid #f4f4f4;

        &:last-of-type {
            border-bottom: none;
        }
    }
}