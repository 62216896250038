#fileTree {
    & ul {
        list-style: none;
        padding: 0;
    }

    & li {
        padding: 0.5rem;
        border-bottom: 1px solid var(--gray-500);

        &:hover,
        &:focus,
        &:focus-within {
            background-color: var(--gray-800);
            font-weight: bold;
        }
        &:last-child {
            border-bottom: 0px;
        }
    }
}
