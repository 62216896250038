#filter .modal-dialog {
    max-width: 100%;
    width: 800px;
}

.filter-badge {
    background-color: var(--success-light);
    border-style: solid;
    border-width: 2px;
    border-color: var(--primary);
    color: var(--primary);
}

.filter-open-modal {
    background-color: var(--success-light);
    color: var(--primary);
}