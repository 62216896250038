.datepicker .datepicker-picker {
    background-color: $dropdown-bg;
    box-shadow: $box-shadow-sm; // Same shadow as our dropdowns (see bs-overrides.scss)
    // Apply Bootstrap dropdown border styles
    border: $dropdown-border-width solid $dropdown-border-color;
    border-radius: $dropdown-border-radius;

    .datepicker-cell {
        &:hover {
            background-color: $dropdown-link-hover-bg;
        }
        &.selected,
        &.selected:hover {
            background-color: $primary;
        }
    }
}

.datepicker-input.in-edit {
    color: var(--bs-body-color) !important;
    background-color: var(--bs-body-bg) !important;
    border-color: #91cdaf !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgba(35, 154, 94, 0.25) !important;
}

.form-floating > .datemask:focus::placeholder {
    color: var(--bs-secondary-color);
    opacity: 0.5;
}