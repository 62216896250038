
.location1, .location2 {
    font-size: 1.6em;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #666666;
}

.location1 {
    color: #24985D;
}

.location2 {
    color: #1F282C;
}

.location3 {
    font-size: 2em;
    color: #a30303;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #666666;
}