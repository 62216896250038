#appBookkeeping {
    .isInBookingIdsIndicator > td {
        background-color: var(--success-light) !important;
        &:first-child {
            border-radius: 0.75rem 0 0 0.75rem !important;
        }
        &:last-child {
            border-radius: 0 0.75rem 0.75rem 0 !important;
        }
    }

    .invoicePdfPreviewContainer {
        height: 1125px !important;
    }

    .truncatedTable {
        overflow: hidden;
        max-width: 10ch;
        text-overflow: ellipsis;
        width: 10ch;
        white-space: nowrap;
    }

    .noRoundedCorners:hover {
        border-radius: 0 !important;
    }

    .bookingTable-action__delete {
        color: var(--bs-danger);
    }

    .bookingTable-action__delete:active {
        color: #fff;
        background-color: var(--bs-danger);
    }

    .full-height {
        @include media-breakpoint-up(xxl) {
            min-height: calc(100vh - var(--main-header-height) - var(--main-footer-height) - 2rem);
        }
    }
    .card {
        border-radius: 1rem;
        border: 1px solid #00000026;


        a {
            color: inherit;
            text-decoration: none;
        }

        .card-body {
            padding-bottom: 0;
        }
        .card-footer {
            border-top: none;
            background-color: transparent;
        }
        .card-header {
            position: relative;
            border-bottom: none;
            background-color: $gray-100;
            padding-bottom: 0;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;

            &:hover {
                .overlay {
                    display: flex;
                }
                .dropdown-button {
                    color: white;
                }
            }

            .placeholderImage {
                height: 5.5rem;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("/static/img/letter.png");
            }

            .dropdown {
                position: absolute;
                right: 0;
                top: 0;

                .dropdown-button {
                    font-size: 1.3rem;
                    padding: 0.5rem 1rem;
                }
            }
            .overlay {
                display: none;
                background-color: #00000094;
                color: white;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
            }
        }
    }

    /* This is absolutely necessary because otherwise the svg graphics on buttons can be click event targets */
    svg {
        pointer-events: none !important;
    }

    /* Same font size as the buttons below this element */
    .openConnectedInvoiceModal {
        font-size: 0.875rem !important;
    }

    th.saldoPill {
        padding-left: 1rem !important;
    }
}