#appBookkeepingInvoice {
    #invoiceCallDetailsIframe {
        height: 75rem;
    }

    #invoiceDetailsDiv {
        @media (min-width: 1920px) {
            height: 75rem;
        }
        @media (max-width: 990px) {
            height: 42rem;
        }
    }

    .truncatedTable {
        overflow: hidden;
        max-width: 10ch;
        text-overflow: ellipsis;
        width: 10ch;
        white-space: nowrap;
    }

    .editPositions {
        min-height: 15rem;
    }
}
