.swal-large {
    max-width: 95%;
    width    : 1024px !important;
}

.swal2-popup {
    font-size: 1.5rem !important;

    & .accordion {
        a {
            text-decoration: none;
        }
        font-size: 1rem;
        text-align: start;
    }
}

.swal2-toast .swal2-html-container {
    font-size: 1rem !important;
}
