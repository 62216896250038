#adminLogs {
    pre {
        border: none;
        padding: 0;
        margin: 0;
        background: none;
        white-space: pre-wrap;
        word-wrap: normal;
        word-break: normal;
    }
    .table > tbody > tr > td {
        border: none;
        padding: 0 8px;
    }
    .logCol-6 {
        border-left: 1px solid black !important;
    }
    .timeMarker {
        padding-top: 1em !important;
    }
    .inlineTimeMarker {
        visibility: hidden;
        color: var(--primary);
        float: right;
    }
    td:hover .inlineTimeMarker {
        visibility: visible;
    }
}
