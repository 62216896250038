[gs-w="1"] .hide-col-w-1 {
    display: none !important;
}

[gs-w="2"] .hide-col-w-2 {
    display: none !important;
}

[gs-w="3"] .hide-col-w-3 {
    display: none !important;
}

.gridTray {
    min-height: 200px;
    color: color-contrast($primary);
    background-color: var(--primary);
    z-index: 1020;
}

.gridTrash {
    min-height: 200px;
    color: color-contrast($primary);
    background-color: var(--primary);
    z-index: 1020;
}

.ui-draggable {
    z-index: 0;
}

.gridTray .ui-draggable {
    z-index: 1040;
}

.ui-draggable-dragging {
    z-index: 1060;
}

.ui-draggable.grid-stack-item:not(.ui-resizable) {
    float: left;
    margin: 10px;
    padding: 10px;
    width: 250px;
    background: white;
    color: var(--primary);
}

#appOverview {
    #balance {
        max-height: 150px;
    }

    #overviewGrid .img-circle {
        height: auto !important;
        width: 2rem !important;
    }

    #remindersList hr {
        margin: 0.5rem;
        margin-left: 0rem;
    }

    #remindersList .reminderIcon {
        background-color: var(--success-light);
        color: var(--success);
        width: 3.4rem;
        height: 3.4rem;
    }

    #boxCommunity .featureText {
        font-size: 1em;
        min-height: 115px;
        margin-bottom: 12px;
    }

    .applicationsList .item, .invoiceRequestList .item, .lendingInquiriesList .item, .cancellationsList .item, .dsgvoChangesList .item, .forumPostList .item {
        a {
            display: none;
        }
    }

    .applicationsList .item:hover, .invoiceRequestList .item:hover, .lendingInquiriesList .item:hover, .cancellationsList .item:hover, .dsgvoChangesList .item:hover, .forumPostList .item:hover {
        a {
            display: inline;
        }
    }

    #linkForumPost{
        display: inline !important
    }

    .carousel-indicators {
        width: 100%;
        background-color: var(--bs-body-bg);
        padding-bottom: 3%;
    }

    .carousel-indicators > button {
        border-radius: 50%;
        width: 0.75rem !important;
        height: 0.75rem !important;
        background-color: var(--primary) !important;
    }

    .custom-carousel-button {
        width: fit-content;
        padding: 0;
        margin: 0;
    }

    /* Ensure the boxCommunity container is positioned relatively */
    #boxCommunity {
        position: relative;
    }

    /* Fix the carousel indicators to the boxCommunity element */
    #boxCommunity .carousel-indicators {
        position: absolute;
        bottom: 10px; /* Adjust the value as needed */
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000; /* Ensures indicators are on top */
    }
}

.overviewGrid {
    position: sticky;
    top: 50px;
    margin: 0;
    z-index: 90;
}

.h-600 {
    height: 600px;
}

.mapMarker {
    font-size: 2em;
    color: #24985D;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #666666;
}

.grid-stack-item-content {
    overflow-y: hidden !important;
}

.grid-stack-item {
    .iconContainer {
        display: none;
    }
}

.grid-stack-item:hover {
    .iconContainer {
        position: absolute;
        left: 5px;
        bottom: 5px;
        z-index: 1000;
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 1px solid var(--danger);
        border-radius: 50%;
        background-color: $body-bg;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ui-resizable-handle {
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: $body-bg !important;
}

.grid-stack > .grid-stack-item > .grid-stack-item-content {
    box-shadow: 0rem 0.4rem 13px rgb(14 34 56 / 11%) !important;
    border-radius: 7px;
}

.grid-stack .box {
    height: 100%;
    border: none !important;
    border-radius: 7px;
    box-sizing: unset !important;
}

.grid-stack-item-content > .box .box-header {
    border-bottom: none !important;
}

.grid-stack-item-content > .box .box-body {
    overflow-y: auto;
    height: calc(100% - 36px);
}

.listBoxShadow {
    margin-bottom: 10px;
    padding: 14px !important;
    border-radius: 7px !important;
    border-bottom: 1px solid #f4f4f4;
}

.products-list.product-list-in-box .listBoxShadow:last-of-type {
    border-bottom: none;
}

.listBoxShadow:hover {
    box-shadow: 0rem 0rem 3px 3px rgba(209, 209, 209, 0.2) !important;
}

.skin-dark {
    .listBoxShadow {
        margin-bottom: 10px;
        padding: 14px !important;
        border-radius: 7px !important;
        border-bottom: 1px solid #3a3a3a !important;
    }
    .products-list.product-list-in-box .listBoxShadow:last-of-type {
        border-bottom: none;
    }
    .listBoxShadow:hover {
        box-shadow: 0rem 0rem 3px 3px rgba(63, 63, 63, 0.2) !important;
    }
    .accountScopeBadge {
        background-color: var(--primary) !important;
        color: white !important;
    }
}

.welcomeBoxImage {
    max-width: 6.25rem;
    max-height: 6.25rem;
    font-size: 3.75rem;
}

.checkCircle {
    border-radius: 50%;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    text-align: center;
}

.welcomeBoxContent {
    width: 100%;

    @media (min-width: 1600px) {
        width: 50%;
    }
}

.aiPill {
    border-radius: 0.5rem;
    padding: 0.05rem 0.3rem;
    font-size: 0.5rem;
    top: 0.5rem;
    left: 0.75rem;
}
