@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// @import "colors.scss";

// Color overwrites - make sure to only change values here to represent what is in colors.scss!
$min-contrast-ratio: 3; // possible values: 3, 4.5 and 7 - Bootstrap 5 defaults to 4.5 which makes text on our primary color black.

$theme-colors: map-merge($map1: $theme-colors, $map2: $colors);

// $primary: hsl(150, 63%, 37%);
// $success: hsl(131, 45%, 44%);
// $warning: hsl(41, 100%, 51%);
// $danger: hsl(354, 70%, 54%);
// $info: hsl(0, 0%, 70%);
// $light: hsl(0, 0%, 80%);
// $dark: hsl(0, 0%, 30%);
// used in appEmailLogs because there is a -25 margin needed
$enable-negative-margins: true;

// Override Colors for the Top Navigation
$navbar-dark-color: rgba($white, .75);
$navbar-dark-hover-color: $white;
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, .25);

// Override table hover
$table-header-default-color: hsl(0, 0%, 95%);
$table-hover-bg: var(--table-header-default-color);

// Override table sorting icon location
.table.dataTable thead > tr > th.sorting::before,
.table.dataTable thead > tr > th.sorting::after {
    left: 0.25rem !important;
}

//Changes curser to "not allowed" symbol when hovering over disabled or readonly fields
fieldset, .form-control, .form-floating, .form-check, .form-check-input, .form-switch, .form-select, button, .btn, a {
    &[disabled], &[readonly],
    & ~ [disabled], & ~ [readonly] {
        cursor: not-allowed !important;
        pointer-events: auto !important;
    }
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  fhd: 1920px,
);

// #region Theme Sepcific overrides ===================================
/*
 * Changes to the 'default' theme should probably not be made here.
 * I hope to mostly use this only for the dark theme.
 */
@if $ev-theme == 'dark' {
    .bg-black .text-light {
        --bs-text-opacity: 1;
        color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
    }
    // #region ck editor
    .ck.ck-editor__main>.ck-editor__editable {
        background-color: $body-bg !important;
        border-color: var(--bs-border-color) !important;
    }
    .ck-toolbar, .ck-toolbar__separator {
        background: $body-bg !important;
        border-color: var(--bs-border-color) !important;
        --ck-color-text: var(--gray-900) !important;
        --ck-color-focus-border: var(--gray-400) !important;
        --ck-color-button-default-active-background: var(--gray-400) !important;
        --ck-color-focus-outer-shadow: var(--gray-400) !important;
        --ck-color-button-on-background: $body-bg !important;
    }
    .ck-reset, .ck-dropdown__panel, .ck-button:hover, .ck-balloon-panel, .ck-input {
        background-color: $body-bg !important;
        color: var(--gray-200) !important;
        border-color: var(--bs-border-color) !important;
    }
    .ck-reset_all, .ck-button, .ck-button-cancel {
        --ck-color-text: var(--gray-900) !important;
        color: var(--gray-900) !important;
    }
    .ck-splitbutton__action, .ck-splitbutton__arrow {
        background-color: $body-bg !important;
    }
    .ck.ck-balloon-panel[class*=arrow_n]:before {
        border-color: transparent transparent var(--bs-border-color) transparent !important;
    }
    .ck.ck-balloon-panel[class*=arrow_n]:after {
        border-color: transparent transparent $body-bg transparent !important;
    }
    // #endregion ck editor

    // #region nav-tabs
    $nav-tabs-link-active-color: var(--primary);
    // #endregion nav-tabs

    // #region Pagination
    $pagination-bg: $gray-100;
    $pagination-color: color-contrast($pagination-bg);
    $pagination-border-color: $gray-300;

    $pagination-hover-bg: shade-color($pagination-bg, $btn-hover-bg-shade-amount);
    $pagination-hover-color: color-contrast($pagination-hover-bg);
    $pagination-hover-border-color: shade-color($pagination-border-color, $btn-hover-border-shade-amount);

    $pagination-focus-bg: $pagination-hover-bg;
    $pagination-focus-color: $pagination-hover-color;

    // $pagination-active-bg: shade-color($pagination-bg, $btn-active-bg-shade-amount);
    // $pagination-active-color: color-contrast($pagination-active-bg);
    // $pagination-active-border-color: shade-color($pagination-border-color, $btn-active-border-shade-amount);


    $pagination-disabled-bg: $pagination-bg;
    $pagination-disabled-color: shade-color(color-contrast($pagination-disabled-bg), 10%);
    $pagination-disabled-border-color: $pagination-border-color;

    // #endregion Pagination

    // #region background
    @for $i from 1 through 15 {
        .bg.bg-#{$i} {
            background: linear-gradient(rgba(27, 28, 29, 0.75), rgba(27, 28, 29, 0.85)), url('/static/img/background/bg-#{$i}.jpg') !important;
        }
    }
    // #endregion background

    // #region Swal
    .swal2-popup {
        background-color: var(--bs-gray-200) !important;
        color: var(--gray-900) !important;
    }
    // #endregion Swal

    // #region alert
    .alert-primary {
        --bs-alert-color: #d3ebdf !important;
        --bs-alert-bg: #155c38 !important;
        --bs-alert-border-color: #42745a !important;
    }
    .alert-primary .alert-link {
        color: #29af6b !important;
    }
    .alert-secondary {
        --bs-alert-color: #e2e3e5 !important;
        --bs-alert-bg: #434b51 !important;
        --bs-alert-border-color: #55616a !important;
    }
    .alert-secondary .alert-link {
        color: #9ba0a5 !important;
    }
    .alert-success {
        --bs-alert-color: #d4edda !important;
        --bs-alert-bg: #2e5237 !important;
        --bs-alert-border-color: #32693e !important;
        --bs-alert-link-color: #47a757 !important;
    }
    .alert-danger {
        --bs-alert-color: #f8d7da !important;
        --bs-alert-bg: #6c373c !important;
        --bs-alert-border-color: #874a50 !important;
    }
    .alert-danger .alert-link {
        color: #ce3946 !important;
    }
    .alert-warning {
        --bs-alert-color: #fff3cd !important;
        --bs-alert-bg: #857543 !important;
        --bs-alert-border-color: #908563 !important;
    }
    .alert-warning .alert-link {
        color: #ffcb58 !important;
    }
    .alert-info {
        --bs-alert-color: #d1ecf1 !important;
        --bs-alert-bg: #2f5860 !important;
        --bs-alert-border-color: #49676d !important;
    }
    .alert-info .alert-link {
        color: #96aeba !important;
    }
    .alert-light {
        --bs-alert-color: #fefefe !important;
        --bs-alert-bg: #818182 !important;
        --bs-alert-border-color: #9898a2 !important;
    }
    .alert-dark {
        --bs-alert-color: #d6d8d9 !important;
        --bs-alert-bg: #2b2e31 !important;
        --bs-alert-border-color: #44494d !important;
    }
    .alert-dark .alert-link {
        color: #92969a !important;
    }
    // #endregion alert

    // #region dropzone
    .dropzone {
        background: $body-bg !important;
    }
    // #endregion dropzone

    // #region license choose
    .licenseChooser-swiperCard.bg-white, .licenseChooser-swiperCard .text-dark {
        background-color: $body-bg !important;
        color: var(--gray-900) !important;
    }
    // #endregion license choose

    // #region accordion
    .accordion-button:not(.collapsed) {
        background-color: $body-bg !important;
        color: white !important;
    }
    .accordion-button:not(.collapsed)::after {
        background-image: var(--bs-accordion-btn-icon) !important;
    }
    // #endregion accordion

    // #region Modal
    .btn-close {
        filter: brightness(1000%) contrast(300%)
    }
    // #endregion Modal

    // #region map canvas
    .ol-layer canvas {
        filter: invert(1) !important;
    }
    // #endregion map canvas

    // #region appShare current folder
    .badge.bg-light {
        background-color: var(--gray-900) !important;
        color: var(--gray-900) !important;
    }
    // #endregion appShare current folder

    // #region placeholdertable
    .placeholderTableParent th {
        background-color: var(--gray-900) !important;
    }
    // #endregion placeholdertable

    // #region setting groups table
    .list-group {
        --bs-list-group-action-hover-color: var(--bs-gray-900) !important;
    }
    // #endregion setting groups table

    // #region form-range
    .form-range {
        &::-webkit-slider-runnable-track {
            background-color: var(--bs-gray-100) !important;
        }
        &::-moz-range-track {
            background-color: var(--bs-gray-100) !important;
        }
    }
    // #endregion form-range

    // #region bubbles (see appEvents)
    .deselected {
        border-style: hidden !important;
        background-color: #494e53 !important;
        color: #c6c7c7 !important;
    }
    // #endregion bubbles
} @else {
    .deselected {
        border-style: hidden !important;
        background-color: #f2f2f2 !important;
        color: #c7c7c7 !important;
    }
}

// #endregion Theme Sepcific overrides ================================

// Import all of Bootstrap's CSS
@import "bootstrap/scss/bootstrap";


// #region Bug Fixes ==================================================
//! Only for actual bugs within Bootstrap
// #region textarea floating label fix
// This temporarily fixes textarea content overflowing the floating label.
// Can be removed, once the issue is fixed: https://github.com/twbs/bootstrap/issues/32800
@mixin floating-label-textarea-fix {
    opacity: 1;
    color: rgba(var(--bs-body-color-rgb), $form-floating-label-opacity);
    background-color: $input-bg;
    height: auto;
    padding: 0px 0px 0px 10px;
    width: 100%;
    transform: scale(0.982) translateY(0.045rem) translateX(0.1rem);
    border-radius: 5px 0 0 0;
}
.form-floating > textarea.form-control {
    &:not(:-moz-placeholder-shown) ~ label {
        padding: 0px;
        @include floating-label-textarea-fix;
    }
    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
        @include floating-label-textarea-fix;
    }
    &:-webkit-autofill ~ label {
        @include floating-label-textarea-fix;
    }
}

// Fixing focused or disabled floating label having a white background
.form-floating {
    & .form-control, & .form-select {
        // Also fixing label going outside input field
        ~ label {
            width: -webkit-fill-available;
            width: -moz-available;
        }
        &:active, &:focus, &:focus-within, &:disabled, &:not(:focus) {
            ~ label::after {
                background: transparent !important;
            }
        }
    }
}

.bg-secondary-lighter {
	background-color: var(--bs-gray-200);
}

// #endregion textarea floating label fix

// #endregion Bug Fixes ===============================================

// #region Extensions =================================================
.form-floating :is(input, select, textarea),
.form-control {
    $margins: 0.5ch;
    max-width: 50vw !important;

    & ~ label {
        & > i, & > svg {
            // Font Awesome icon in the floating label
            margin-right: $margins;
        }
    }

    &[required] ~ label {
        // If the field is required change the color to primary and add the * after the label text automatically.
        & > i, & > svg {
            color: var(--primary);
        }

        &:after {
            content: "*";
            margin-left: $margins;
        }
    }
}

@media only screen and (max-width: 2560px) {
    .form-floating :is(input, select, textarea),
    .form-control {
        max-width: 100vw !important;
    }
}

.form-switch .form-check-input {
    width: 3em !important;
    height: 1.5em !important;
}

.show-label-first {
    display: flex;
    flex-direction: column-reverse;
}

.nav-search {
    color: $navbar-dark-color;
    #searchDataTable {
        background-color: shift-color($color: $primary, $weight: 10%);
        color: $navbar-dark-active-color;
        border-color: transparent;
        border-top: unset;
        min-width: 19rem;
        padding-right: 2.4rem;
    }
    .form-floating label::after {
        & > i, & > svg {
            color: $navbar-dark-color;
        }
        &:active, &:focus, &:focus-within {
            & > i, & > svg {
                color: $navbar-dark-active-color;
            }
        }
    }

    #emptySearch {
        background-color: shift-color($color: $primary, $weight: 10%);
        border-radius: 0;
        margin-top: -2.4rem;
        border: none;
        float: right;
    }
}

/*
 * Floating Label Typeahead.js support
 * Much details are copied from the Bootstrap floating_label.scss.
 * It works, but the label is always floating.
 * This can not be fixed, as the label is not a sibling to the input anymore
 */
.form-floating {
    $margins: 0.5ch;
    /*
     * Typeahead requires extra work to make `required` work as expected.
     * We add this .required-typeahead in JS when creating a typeahead instance on an input that is required.
    */
    &.required-typeahead > label {
        & > i, & > svg {
            color: var(--primary);
        }
        &:after {
            content: "*";
            margin-left: $margins;
        }
    }
    & > .twitter-typeahead {
        height: $form-floating-height;
        & > .form-control {
            height: $form-floating-height;
            line-height: $form-floating-line-height;
            padding: $form-floating-padding-y $form-floating-padding-x;
            padding-top: $form-floating-input-padding-t;
            padding-bottom: $form-floating-input-padding-b;
        }
        & ~ label {
            opacity: 0.65;
            transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
            & > i, & > svg {
                // Font Awesome icon in the floating label
                margin-right: $margins;
            }
        }
    }
}

.dropdown-menu {
    // Enables a small drop shadow for dropdown menus
    box-shadow: $box-shadow-sm;
}

@media only screen and (max-width: 450px) {
    .dropdown-item {
        // Prevents dropdown items from going off screen on phones
        white-space: normal;
    }
}

.tab-content.bordered {
    border: 1px solid var(--bs-gray-400);
    border-top: none;
    border-radius: 0 0 $border-radius $border-radius;
    padding: 1rem;
}

.modal-footer [data-bs-dismiss="modal"]:first-child {
    // Automatically aligns the left-most button the the left of the footer, if it is the close button.
    // If there are multiple close buttons, only the first will be aligned.
    margin-right: auto;
}

.select-us__selection-input.form-control {
    // Make readonly selectUs input field look like a normal input
    &:disabled,
    &[readonly] {
        background-color: $input-bg;
        border-color: $input-border-color;
    }
}
// #endregion Extensions ==============================================

// #region Compatibility Mods =========================================
.img-circle {
    border-radius: 50%;
}

.floatingSelect,
.tooltip {
    text-transform: none;
}

.tooltip-inner {
    color: white !important;
}
// #endregion Compatibility Mods ======================================