/* CKEditor5 Hack for Modals */

body {
    --ck-z-default: 100;
    --ck-z-modal: calc( var(--ck-z-default) + 999 );
}

.ck-body-wrapper {
    z-index: 9999 !important;
}

.ck-dropdown__arrow {
    z-index: 99 !important; // cause typeAhead listbox is z-index 100
}
