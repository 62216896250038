.headerbar {
  [data-ordering] {
    position: relative;
    display: block;
    cursor: pointer;

    &::after,
    &::before {
      position: absolute;
      display: block;
      opacity: 0.125;
      right: 0;
      line-height: 9px;
      font-size: 0.9em;
    }
    &::before {
      bottom: 50%;
      content: "▴";
    }
    &::after {
      top: 50%;
      content: "▾";
    }

    &[data-reverse="true"]::before {
      opacity: 0.6;
    }
    &[data-reverse="false"]::after {
      opacity: 0.6;
    }
  }
}